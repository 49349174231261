import { useState } from "react"
import GeneralStyles from "../../GeneralStyles"
import ToolHeader from "../../components/ToolHeader"
import TopicBar from "../../components/TopicBar"
import LocationSection from "./LocationSection"
import { useLocation } from "react-router-dom"
import data from './heatwaveAnalysis/heatwaveData.json'
import prepHeatWaveTable from './prepHeatWaveTable'
import useCheckPath from "../../tools/useCheckPath"
import DataTable from "../../components/DataTable/DataTable"
import prepHeatWaveChart from "./prepHeatWaveChart"
import HeatWaveChart from "./HeatWaveChart"

const HeatWaves = () => {

    //** sync topic state with path */
    let { pathname } = useLocation()
    useCheckPath(pathname)

    const [county, setCounty] = useState('')

    let filteredData = !!county ? data.filter(item => item.County.toLowerCase() === county.toLowerCase()) : []

    console.log('filtered', filteredData)

    let { output, threshold } = prepHeatWaveTable(filteredData)
    let { output: chartData } = prepHeatWaveChart(filteredData)
    let tableColumns = [" ",
        'Average Number of Annual Heat Waves',
        'Average Days of Heat Wave Duration',
        'Maximum Days of Heat Wave Duration']

    let caption = !!threshold && <><span span style={{ fontWeight: 'bold' }
    }> The threshold temperature for a heat wave in {county} County is {threshold}˚F.</span> </>

    // console.log(chartData)

    return (
        <GeneralStyles.PageCont>
            <TopicBar />
            <ToolHeader />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <LocationSection county={county} setCounty={setCounty} />
                {!!output.length && <>
                    <HeatWaveChart data={chartData} />
                    <div style={{margin: '2rem'}}>

                    <DataTable data={output} header={tableColumns} topCaption={caption} />
                    </div>

                </>}
            </div>
        </GeneralStyles.PageCont>
    )
}
export default HeatWaves