const prepHeatWaveTable = data => {
    let threshold
    let output = data.map(item => {
        let numAnnual
        if (item["10% Annual Heatwave Number"] !== "N/A") {
            numAnnual = `${item['Annual Mean Heatwave Number']} (${item["10% Annual Heatwave Number"]} - ${item["90% Annual Heatwave Number"]})`
        } else {
            numAnnual = item[`Annual Mean Heatwave Number`].toString()
        }

        let meanDuration
        if (item["10% Annual Heatwave Duration (Days)"] !== "N/A") {
            meanDuration = `${item['Annual Mean Heatwave Duration (Days)']} (${item["10% Annual Heatwave Duration (Days)"]} - ${item["90% Annual Heatwave Duration (Days)"]})`
        } else {
            meanDuration = item[`Annual Mean Heatwave Duration (Days)`].toString()
        }

        let maxDuration
        if (item["10% Annual Max Heatwave Duration (Days)"] !== "N/A") {
            maxDuration = `${item['Annual Max Heatwave Duration (Days)']} (${item["10% Annual Max Heatwave Duration (Days)"]} - ${item["90% Annual Max Heatwave Duration (Days)"]})`
        } else {
            maxDuration = item[`Annual Max Heatwave Duration (Days)`].toString()
        }

        let itemOut = {
            dataset: item.Dataset,
            numAnnual,
            meanDuration,
            maxDuration,
        }
        threshold = item["Threshold (˚F)"]

        return itemOut
    })
    return { output, threshold }
}

export default prepHeatWaveTable