
const prepareStdSeries = (data) => {

    //** want to output an array of series for each type of storm (2year, 5year, etc) */
    // console.log('prepareSeries', data)
    let scenarios = [`Atlas14`,
        "rcp45_2020-2069",
        "rcp45_2050-2099",
        "rcp85_2020-2069",
        "rcp85_2050-2099"]
    let year2 = {
        id: 'year2',
        name: '50% chance (2-Year storm)',
        data: [],

    }
    let year5 =
    {
        id: 'year5',
        name: '20% chance (5-Year storm)',
        data: [],

    }
    let year10 =
    {
        id: 'year10',
        name: '10% chance (10-Year storm)',
        data: [],

    }
    let year25 =
    {
        id: 'year25',
        name: '4% chance (25-Year storm)',
        data: [],

    }
    let year50 =
    {
        id: 'year50',
        name: '2% chance (50-Year storm)',
        data: [],

    }
    let year100 =
    {
        id: 'year100',
        name: '1% chance (100-Year storm)',
        data: [],

    }


    scenarios.forEach(item => {
        if (item === 'Atlas14') {
            year2.data.push(data[item][`24hr_mean`][`2.0`])
            year5.data.push(data[item][`24hr_mean`][`5.0`])
            year10.data.push(data[item][`24hr_mean`][`10.0`])
            year25.data.push(data[item][`24hr_mean`][`25.0`])
            year50.data.push(data[item][`24hr_mean`][`50.0`])
            year100.data.push(data[item][`24hr_mean`][`100.0`])
        } else {
            year2.data.push(data[item][`24hr`][`2.0`])
            year5.data.push(data[item][`24hr`][`5.0`])
            year10.data.push(data[item][`24hr`][`10.0`])
            year25.data.push(data[item][`24hr`][`25.0`])
            year50.data.push(data[item][`24hr`][`50.0`])
            year100.data.push(data[item][`24hr`][`100.0`])
        }
    })
    let output = [year2, year5, year10, year25, year50, year100]

    return output

};

export default prepareStdSeries;
