import React from "react";
import "./GenericButton.css";

const GenericButton = ({ text, onClick, colors }) => {
  return (
    <div
      className={`generic-button button`}
      style={{
        backgroundColor: colors.background ?? null,
        color: colors.text ?? null,
      }}
      onClick={onClick}
    >
      <p>{text}</p>
    </div>
  );
};

export default GenericButton;
