import { useContext, useEffect, useState } from "react"
import AreaRangeHighchart from "./AreaRangeHighchart/AreaRangeHighchart"
import AppContext from "../../AppContext"
import ScenarioBlurb from "../ScenarioBlurb"
import { span } from "../../data/variables"
import fetcher from "../../tools/swrFetcher.js"
import useSWR from "swr"

const ChartSection = () => {

    const [state] = useContext(AppContext)
    const { activeParam: { param }, activeRcp: { chartVar: scenario }, activeSeason: { chartVar: season } } = state
    
    const baseURL = process.env.REACT_APP_API_URL

    const [chartData, setChartData] = useState([])
    const [chartHist, setChartHist] = useState([])
    
    const { data: histData, error: histError, isLoading: histLoading } = useSWR(
        `${baseURL}/api/historical`,
        fetcher
    );

    const { data, error, isLoading } = useSWR(
        `${baseURL}/api/data`,
        fetcher
    );

    useEffect(() => {
        setChartHist(histData?.averages?.filter((item) => item.month === season).map((item) => [item.year, item[`${param}_prism`]]))
    }, [histData, param, season])

    
    useEffect(() => {
        let filteredData = data?.data?.filter((item) => item.month === season && item.rcp === scenario) ?? []
        console.log('filteredData', filteredData)
        let averages = filteredData.map(item => [item.year, item[`${param}_wmean`]])
        let ranges = filteredData.map(item => [item.year, item[`${param}_all10`], item[`${param}_all90`]])
        setChartData({averages, ranges})
    }, [data, param, season, scenario])

    
    let yearRange = [];
    yearRange.push(state.activeYear.chartVar - (span - 1));
    yearRange.push(state.activeYear.chartVar);

    // console.log('data', chartData, error, isLoading, season, param, scenario)
    // console.log('historical', chartHist, histError, histLoading, season, param)


    return isLoading || histLoading
        ? <h2>Gathering fresh data...</h2>
        : error || histError
            ?
            <>
                {console.log('error', error, histError)}
                <h2> Something has gone wrong while gathering data.Hit refresh to try again.</h2>
            </>
            : data && histData ?
                <div style={{ padding: '2rem' }}>
                    <AreaRangeHighchart color={state.activeColors}
                        chartOptions={{
                            margin: 0,
                            title: {
                                text: `Modeled Statewide ${state.activeSeason.mapName} ${state.activeParam?.chartTitle}`,
                            },

                            xAxis: {
                                labels: {
                                    formatter() {
                                        return this.pos;
                                    },
                                },
                                plotBands: [
                                    {
                                        color: "#f1f1f1",
                                        from: yearRange[0],
                                        to: yearRange[1],
                                    },
                                ],
                            },
                            yAxis: {
                                title: {
                                    text: state.activeParam?.chartYAxisText,
                                },
                                max: state.activeParam?.chartYAxisMax,
                                labels: {
                                    formatter: function () {
                                        return this.value;
                                    },
                                },
                            },
                            tooltip: {
                                crosshairs: true,
                                shared: true,
                            },
                            series: [
                                {
                                    name: "Range",
                                    type: "arearange",
                                    data: chartData.ranges,
                                    lineWidth: 0,
                                    linkedTo: "main",
                                    color: `${state.activeColors.background}`,
                                    fillOpacity: 0.3,
                                    marker: {
                                        enabled: false,
                                    },
                                },
                                {
                                    id: "main",
                                    dashStyle: "Dash",
                                    name: "Projected Values",
                                    data: chartData.averages,
                                    lineColor: state.activeColors.background,
                                    marker: {
                                        fillColor: state.activeColors.background,
                                    },
                                },

                                {
                                    name: "Historical Values",
                                    type: "line",
                                    data: chartHist,
                                    lineColor: "#9a9b9c",
                                    marker: {
                                        fillColor: "#9a9b9c",
                                    },
                                },
                            ],
                        }} />
                    <ObservedBlurb />
                    <ScenarioBlurb rcp={state.activeRcp.chartVar} />
                </div>
                : <h2>Something has gone wrong while gathering data. Hit refresh to try again.</h2>
}
export default ChartSection

const ObservedBlurb = () => (
    <div style={{ margin: "2rem" }}>
        The above chart shows the projections of 32 climate models. The broad
        colored band shows the range of values that the models projected. The
        narrower the band, the more strongly models agree. The dashed line shows the
        average of those projections. The solid line shows the actual values that
        already occurred. They do not line up exactly with the average modeled
        values but do fall within the range, indicating the models are projecting
        realistic values.
    </div>
);