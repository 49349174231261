import React, {useRef} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import HC_exporting from 'highcharts/modules/exporting'

HC_exporting(Highcharts)
highchartsMore(Highcharts);

const AreaRangeHighchart = ({ chartOptions }) => {
const chartRef = useRef(null);
  
  return (
    <div>
      <HighchartsReact

        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
    </div>
  );
};

export default AreaRangeHighchart;
