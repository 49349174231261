exports.dataSources = [
  {
    title: `Applied Climate Information System`,
    link: "http://www.rcc-acis.org/",
    img: "components/ACIS-2014.png",
    desc: `The Applied Climate Information System (ACIS) was developed and is
            maintained by the NOAA Regional Climate Centers (RCCs). It was designed
            to manage the complex flow of information from climate data collectors
            to the end users of climate data information. The main purpose of ACIS
            is to alleviate the burden of climate information management for people
            who use climate information to make management decisions.  Thank you to the Northeast Regional Climate Center for assistance with the data used to create this tool.`,
  },
];
