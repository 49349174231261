
import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components'
import Welcome from "../Welcome";

const Styles = {
  Header: styled.header`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${({ theme }) => theme.RURed};
    // box-shadow: ${({ theme }) => theme.boxShadow};
  `,
  SiteHeader: styled.nav`
    width: 1355px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    padding: 2rem;
    color: white;
    font-size: 2rem;
    margin-right: 1em;
    font-weight: 500;
    
  `,
  LogoContainer: styled.div`
  display: flex;
  flex: 1;
  img {
   height: 100%;
   padding-left: 1rem;
  }
  `,
  SiteMenu: styled.div`
  li {
    display: inline;
    margin-right: 2rem;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  li:last-child{
    margin-right: 0;
  }
  `,
  OutieLink: styled.a`
  &:link, &:visited, &:hover, &:active {
    color: white;
  text-decoration: none;

  }

  `,
}

const SiteHeader = ({ modalStatus }) => {

  // console.log('modalStatus', modalStatus)
  return (
    <Styles.Header>
      <Styles.SiteHeader>
        <Styles.LogoContainer>
          <Link to="/" >
            <img src='https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2020/03/NJCCRC-website-logo-260x24.png' alt='Rutgers NJCCRC website logo' />
          </Link></Styles.LogoContainer>
        <Welcome modalStatus={modalStatus} />
      </Styles.SiteHeader>
    </Styles.Header>
  );
};

export default SiteHeader;
