import { useContext } from "react";
import { seasons } from "../data/variables";
import RadioWraps from "./radioInputs/RadioWraps";
import AppContext from "../AppContext";

const SeasonRadios = () => {

    const [state, dispatch] = useContext(AppContext)

    let seasonElems = seasons.map((item, index) => (
        <RadioWraps
            name={item.name}
            text={item.name}
            key={index}
            active={state.activeSeason.chartVar === item.chartVar}
            handleClick={() => {
                const action = { type: 'SET_ACTIVE_SEASON', payload: item }
                dispatch(action);
            }}
        />
    ))
    return seasonElems
}
export default SeasonRadios