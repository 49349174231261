import { years } from "../data/variables";
import AppContext from "../AppContext";
import RadioWraps from "./radioInputs/RadioWraps";
import { useContext } from "react";

const YearsRadios = () => {

    const [state, dispatch] = useContext(AppContext)

    let yearElems = years.map((item, index) => (
        <RadioWraps
            name={item.year}
            text={item.year}
            key={index}
            active={state.activeYear.chartVar === item.chartVar}
            handleClick={() => {
                if (item.chartVar === 2020 && state.change) {
                    const action = { type: 'TOGGLE_CHANGE' }
                    dispatch(action);
                }
                const action = { type: 'SET_ACTIVE_YEAR', payload: item }
                dispatch(action);
            }}
        />
    ))


    return yearElems
}
export default YearsRadios