// import calcRpCust from "./calcRpCust";
import calcAmount from "./calcAmount";
import calcProbRainfall from "./calcProbRainfall";

const prepareCustSeries = (value, data, type) => {
  //** want to output an array of series for each type of storm (2year, 5year, etc) */
  let scenarios = [`Atlas14`,
    "rcp45_2020-2069",
    "rcp45_2050-2099",
    "rcp85_2020-2069",
    "rcp85_2050-2099"]

  let meanData = []
    
  scenarios.forEach(item => {
    let a, b
    if (item === 'Atlas14') {
      a = data[item][`24hr_mean`][`regression_params`][0]
      b = data[item][`24hr_mean`][`regression_params`][1]
    } else {
      a = data[item][`24hr`][`regression_params`][0]
      b = data[item][`24hr`][`regression_params`][1]
    }

    if (type === 'inches') {
      
      //** calcRpCust takes entered value and defines power as (amount-b)/a, then returns e to the power (Math.exp(power).toFixed(2)) */
      let rp = Number(calcProbRainfall(value, a, b))
      meanData.push(rp)

    }
    if (type === 'chance') {
      console.log('result is rainfall amount')
      let rp = 1/(value/100)
      // calcAmount is a * the natural log of value  plus b
      let amount = Number(calcAmount(rp, a, b))
      meanData.push(amount)
    }
  })
console.log('result by scenario', meanData)
  return meanData
};

export default prepareCustSeries;
