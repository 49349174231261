import { useContext } from "react";
import styled from 'styled-components'
import Modal from "./Modal";
import ToolBottom from "./ToolBottom/ToolBottom";
import AppContext from "../AppContext";

const Styles = {
  ToolWrap: styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2em 0;
  color: black;
  text-align: center;
  h2 {
    font-weight: 400;
    font-size: 3.4rem; 
  }
  p {
    font-size: 1.25rem;
  }
  `,
  AboutWrap: styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 1rem 0;
  color: ${({ theme }) => theme.RUTeal}
  
  `,
  ModalCont: styled.div`
  display: ${({ active }) => active ? 'relative' : 'none'};
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  `,
  ModalWin: styled.div`
  width: 50%;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  filter: blur(0);
  transform: scale(1);
  `,
  AboutDiv: styled.div`
  margin: 1rem;
  font-weight: bold;
  @media screen and (max-width:${({ theme }) => theme.mobile}) {
    width: 100%;
    margin: 0 1rem;
    
  }
  `,
  TopWrap: styled.section`
  
  align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100vw;
    border-bottom: 1px solid black;

    @media screen and (max-width: 1350px) {
      // flex-direction: column;
      // justify-content: center;

      margin: 0 1rem;
    }
  `,
}

const ToolHeader = () => {

  const [state] = useContext(AppContext)
  // console.log('state', state)

  let height = window.innerHeight;
  return (
    <Styles.TopWrap>
      <Styles.ToolWrap>
        <h2>{state.activeParam.title}</h2>

        <Styles.AboutWrap>
          <Styles.AboutDiv style={{ textAlign: 'left', fontWeight: 'normal' }}>
            {state.activeParam.topText}
          </Styles.AboutDiv>
          {height >= 700 && (
            <>
              <Styles.AboutDiv>{state.activeParam.what}
                <Modal what={state.activeParam.what} info={state.activeParam.description} />
              </Styles.AboutDiv>
              <Styles.AboutDiv>About the Data
                <Modal style={{ width: '90vw' }} info={<ToolBottom about={state.activeParam.about} />} what={'About the Data'} />
              </Styles.AboutDiv>
            </>
          )}
        </Styles.AboutWrap>
      </Styles.ToolWrap>
    </Styles.TopWrap>
  );
};

export default ToolHeader;




