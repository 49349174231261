import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import HC_exporting from 'highcharts/modules/exporting'
import { theme } from '../../components/theme'

HC_exporting(Highcharts)
highchartsMore(Highcharts);

const HeatWaveChart = ({ data }) => {

    // console.log('chart', data)
    // //** add custom colors to data series - 6 colors for 6 series */
    let { RUTeal, RUGreen, RUYellow, RUOrange, RURed } = theme
    let colors = [RURed, RUOrange, RUGreen, RUYellow, RUTeal]
    let coloredData = data?.map((item, i) => ({ ...item, color: colors[i] }))

    let width = window.innerWidth * .9;
    const chartRef = useRef(null);

    const chartOptions = {
        chart: {
            width: width,
            type: 'column',
        },
        title: {
            text: 'Duration of Heat Wave Events',

        },
        xAxis: {
            categories: [
                'Average Duration',
                'Maximum Duration'
            ],
            crosshair: true
        },
        yAxis: { // Secondary yAxis
            gridLineWidth: 0,
            title: {
                text: 'Days',
            },
            labels: {
                format: '{value} days',
            },
            // opposite: true,
            // max: 15,
        },
        tooltip: {
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.2f} days</b></td></tr>',
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,

                borderWidth: 0
            }
        },
        series: coloredData
    }

    return (
        <div style={{margin: '2rem'}}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
            />
        </div>
    );
};

export default HeatWaveChart;
