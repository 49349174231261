const prepHeatWaveChart = data => {
    let threshold
    let output = data.map(item => {


        let meanDuration = item[`Annual Mean Heatwave Duration (Days)`]


        let maxDuration = item[`Annual Max Heatwave Duration (Days)`]


        let itemOut = {
            name: item.Dataset,
            data: [meanDuration,
                maxDuration]
        }
        threshold = item["Threshold (˚F)"]

        return itemOut
    })
    return { output, threshold }
}

export default prepHeatWaveChart