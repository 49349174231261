export const rcp = [
  {
    scenario: "Moderate Emissions",
    description: "emissions peak around 2040 then decline",
    mapVar: "RCP45",
    chartVar: "rcp45",
  },
  {
    scenario: "High Emissions",
    description:
      "emissions continue to rise strongly through 2050 and plateau around 2100",
    mapVar: "RCP85",
    chartVar: "rcp85",
  },
];

export const seasons = [
  { name: "Winter", def: "(Dec, Jan, Feb)", mapName: "Winter", chartVar: 2 },
  { name: "Spring", def: "(Mar, Apr, May)", mapName: "Spring", chartVar: 5 },
  { name: "Summer", def: "(Jun, Jul, Aug)", mapName: "Summer", chartVar: 8 },
  { name: "Fall", def: "(Sep, Oct, Nov)", mapName: "Fall", chartVar: 11 },
];

export const span = 20;

// 20 year spans
export const years = [
  // { year: "1981-2000", chartVar: 1990 },
  { year: "2001-2020", chartVar: 2020 },
  { year: "2021-2040", chartVar: 2040 },
  { year: "2041-2060", chartVar: 2060 },
  { year: "2061-2080", chartVar: 2080 },
  { year: "2081-2100", chartVar: 2100 },
];

// 30 year spans
// export const years = [
//   { year: "1991-2020", chartVar: 2020 },
//   { year: "2021-2050", chartVar: 2050 },
//   { year: "2051-2080", chartVar: 2080 },
//   // { year: "2081-2099", chartVar: 2099 },
// ];
