import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import HC_exporting from 'highcharts/modules/exporting'
import { theme } from "../../components/theme"

HC_exporting(Highcharts)
highchartsMore(Highcharts);

const InchesChart = ({ data }) => {
    // console.log(data)
    //** add custom colors to data series - 6 colors for 6 series */
    // let { RUBlue, RUTeal, RUGreen, RUYellow, RUOrange, RURed } = theme
    // let colors = [RURed, RUBlue, RUOrange, RUGreen, RUYellow, RUTeal]
    // let coloredData = data?.map((item, i) => ({ ...item, color: colors[i] }))

    let width = window.innerWidth * .9;
    const chartRef = useRef(null);

    const chartOptions = {
        chart: {
            width: width,
            type: 'column',
        },
        title: {
            text: 'Rainfall in inches',

        },
        xAxis: {
            categories: [
                'Historical',
                'Moderate Emissions, mid-century',
                'Moderate Emissions, late-century',
                'High Emissions, mid-century',
                'High Emissions, late-century',
            ],
            crosshair: true
        },
        yAxis: { // Secondary yAxis
            gridLineWidth: 0,
            title: {
                text: 'Rainfall in inches',
            },
            labels: {
                format: '{value} in',
            },
            // opposite: true,
            max: 15,
        },
        // tooltip: {
        //     pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //         '<td style="padding:0"><b>{point.y:.2f} in</b></td></tr>',
        // },
        plotOptions: {
            column: {
                pointPadding: 0.2,

                borderWidth: 0
            }
        },
        series: [{
            name: 'Inches of rainfall',
            color: theme.RUTeal,
            data: data
        }]
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
            />
        </div>
    );
}
export default InchesChart