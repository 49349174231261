import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import { FaInfoCircle } from 'react-icons/fa'
import { theme } from './theme'

const Modal = ({ what, info }) => {
    const modalRef = useRef();
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(prev => !prev)
    }

    // if you press escape key closes modal
    const keyPress = useCallback(evt => {
        if (evt.key === 'Escape' && showModal) {
            setShowModal(false)
        }
    }, [setShowModal, showModal])

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress)
    }, [keyPress])

    // click in background to close modal
    const closeModal = evt => {
        modalRef.current === evt.target && setShowModal(false)
    }

    return (
        <>
            <FaInfoCircle
                style={{ margin: '0 1rem' }}
                color={theme.RUTeal}
                onClick={openModal}
            />
            {showModal && (
                <Styles.Background ref={modalRef} onClick={closeModal}>

                    <Styles.ModalWrapper showModal={showModal}>

                        <Styles.ModalContent>

                            <h1>{what}</h1>
                            <p>{info}</p>

                        </Styles.ModalContent>
                        <Styles.CloseModalButton aria-label='close modal' onClick={() => setShowModal(false)} />
                    </Styles.ModalWrapper>

                </Styles.Background>
            )}
        </>
    )
}

export default Modal

const Styles = {

    Background: styled.div`
    width: 100vw;
    height: 100vh;
    background: #00000080;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    
    `,
    ModalWrapper: styled.div`
    width: 80rem;
    box-shadow: 0 5px 16px rgba(0,0,0,0.2);
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: 1s alternate fadein;
  @keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 100;
    }
  };
  
    `,
    ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 2rem;
    line-height: 1.8;
    color: ${({ theme }) => theme.RUTeal};
    background: #f1f1f1;
    overflowY: scroll;
    h1 {
        font-size: 4rem;
        font-weight: 300;
    }

    p {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        font-weight: 500;
    }
    
    `,

    CloseModalButton: styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding 0;
    z-index: 10;
    `,

}