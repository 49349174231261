import React from 'react'
import styled from 'styled-components'

const TopicButton = ({active, source, text, handleClick}) => {
  return (
    <Styles.Wrapper active={active} onClick={handleClick}>
        <img src={source} alt='topic icon'/>
        <span>{text}</span>
    </Styles.Wrapper>
  )
}

export default TopicButton

const Styles = {
    Wrapper: styled.div`
    flex: 1;
    width: 200px;
    height: 125px;
    margin: 0.5rem 0.25rem;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({active}) => active ? '#dcdedf' : 'whitesmoke'};
    img{
        width: 25%;
    }
    span{
        text-align: center;
        padding: 0 2rem;
    }
    &:hover {
      cursor: pointer;
      background-color: #dcdedf;
    }
    media screen and (max-width:${({theme}) => theme.mobile}) {
      width: 100%;
    }
    `
}