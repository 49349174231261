import { useState } from "react"
import GeneralStyles from "../../GeneralStyles"
import GenericButton from "../../components/GenericButton/GenericButton"
import prepareCustSeries from "./prepareCustSeries"
import ChanceChart from './ChanceChart'
import InchesChart from "./InchesChart"
import RainTable from "./RainTable"
import { theme } from "../../components/theme"


const CustomSection = ({ data }) => {
    const [customBase, setCustomBase] = useState('')
    const [chartData, setChartData] = useState([])
    const [input, setInput] = useState('')


    const handleChoice = (choice) => {
        setCustomBase(choice)
        setChartData([])
        setInput('')
    }


    const handleInput = ({ target }) => {
        let { value } = target

        let output = prepareCustSeries(value, data, customBase)
        setInput(value)
        setChartData(output)
    }

    return (
        <GeneralStyles.SectionCont style={{ marginTop: '5rem' }}>
            <h2>Build your own storm event</h2>
            <h3>Choose to build a storm based on probability or rainfall amount in inches</h3>
            <div style={{ display: 'flex', width: '50%', justifyContent: 'space-evenly', margin: '2rem' }}>
                <GenericButton
                    text='Probability'
                    onClick={() => handleChoice('chance')}
                    colors={{ background: customBase === 'chance' ? theme.RUTeal : 'white', text: customBase === 'chance' ? 'white' : theme.RUTeal }}
                />
                <GenericButton
                    text='Inches'
                    onClick={() => handleChoice('inches')}
                    colors={{ background: customBase === 'inches' ? theme.RUTeal : 'white', text: customBase === 'inches' ? 'white' : theme.RUTeal }}
                />
            </div>
            <div style={{ maxWidth: '800px' }}>
                {/* <p>For the purposes of this tool, a storm refers to the number of inches of rain that fall in a 24-hour period.  The return period represents the chance that a storm could occur in any given year.  If a storm has a 1:100 (1%) chance of occurring in any given year, historically this has been called a 100-year storm.  Following that logic, a 2-year storm would be a storm that has a 50% (1:2) chance of occurring in any given year.</p> */}

                {/* 50/100  2/.5*/}

                {customBase === 'inches' && <p style={{ margin: '1rem' }}>Enter the number of inches for a 24-hr storm event to find out how likely it is for a storm of that magnitude to occur in {data.town_name}. </p>}
                {customBase === 'chance' && <p style={{ margin: '1rem' }}>Enter the % chance to find out how much rain could be expected for a 24-hr storm event in {data.town_name}.</p>}
            </div>
            {!!customBase && (
                <div style={{ margin: '2rem' }}>

                    <input id='data' onChange={handleInput} style={{ padding: '1rem' }} type='number' value={input} />
                    <label style={{ marginLeft: '2rem' }} htmlFor="data">{customBase === 'inches' ? 'inches' : '% chance'}</label>

                </div>
            )}

            <div style={{ marginBottom: '5rem' }}>
                {(!!chartData.length && customBase === 'inches') ? <><ChanceChart data={chartData} />
                    <RainTable data={chartData} caption='Percent Chance' /></> : (!!chartData.length && customBase === 'chance') ? <>< InchesChart data={chartData} /><RainTable data={chartData} caption='Rainfall in inches' /></> : null}
            </div>


        </GeneralStyles.SectionCont>
    )
}
export default CustomSection