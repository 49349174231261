import { useContext, useEffect, useState } from "react"
import Stats from "./Stats"
import AppContext from "../../AppContext"
import fetcher from "../../tools/swrFetcher.js"
import useSWR from "swr"

const StatsSection = () => {
    const [state] = useContext(AppContext)
    const { activeParam: { param }, activeYear: { chartVar: period }, activeRcp: { chartVar: scenario }, activeSeason: { chartVar: season } } = state

    const baseURL = process.env.REACT_APP_API_URL


    const { data: stats, error, isLoading } = useSWR(
        `${baseURL}/api/data/stats`,
        fetcher
    );
    
    const [statsData, setStatsData] = useState({})

    useEffect(() => {
        let yearSpan = 20
        let minYear = period - yearSpan + 1;
        let maxYear = Number(period); // make sure this is catching 2020
        let count = maxYear === 2100 ? 3 : 4
        
        let filteredStats = stats?.data?.filter((item) => item.month === season && item.rcp === scenario && item.year >= minYear && item.year <= maxYear) ?? []
        let average = (filteredStats.map(item => item[`${param}_wmean`]).reduce((a, b) => a + b, 0)) / count
        let max = (filteredStats.map(item => item[`${param}_all90`]).reduce((a, b) => a + b, 0)) / count
        let min = (filteredStats.map(item => item[`${param}_all10`]).reduce((a, b) => a + b, 0)) / count
        setStatsData({average, max, min})
    }, [stats?.data, param, period, scenario, season])

    console.log('stats', !!Object.keys(statsData).length, error, isLoading)

    return isLoading
        ? <h2>Gathering fresh data...</h2>
        : error
            ? <>{console.log('stats error', error)}<h2>Something has gone wrong while gathering data for stats. Hit refresh to try again.</h2></>
            : !!Object.keys(statsData).length ? <Stats
        color={state.activeColors}
        title={`${state.activeParam?.statsTitle} for ${state.activeYear.year} ${state.activeParam?.statsUnit}`}
        values={statsData}
    /> :
    <h2>Something has gone wrong while gathering data for stats. Hit refresh to try again.</h2>

}
export default StatsSection