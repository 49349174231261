const munis = [{MUN_LABEL: 'Deerfield Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0603'},
{MUN_LABEL: 'Hopewell Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0607'},
{MUN_LABEL: 'Buena Borough',
COUNTY: 'ATLANTIC',
MUN_CODE: '0104'},
{MUN_LABEL: 'Port Republic City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0120'},
{MUN_LABEL: 'Lower Alloways Creek Township',
COUNTY: 'SALEM',
MUN_CODE: '1705'},
{MUN_LABEL: 'Upper Deerfield Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0613'},
{MUN_LABEL: 'Vineland City',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0614'},
{MUN_LABEL: 'Elsinboro Township',
COUNTY: 'SALEM',
MUN_CODE: '1704'},
{MUN_LABEL: 'Newfield Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0813'},
{MUN_LABEL: 'Quinton Township',
COUNTY: 'SALEM',
MUN_CODE: '1712'},
{MUN_LABEL: 'Beach Haven Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1504'},
{MUN_LABEL: 'Salem City',
COUNTY: 'SALEM',
MUN_CODE: '1713'},
{MUN_LABEL: 'Hamilton Township',
COUNTY: 'ATLANTIC',
MUN_CODE: '0112'},
{MUN_LABEL: 'Buena Vista Township',
COUNTY: 'ATLANTIC',
MUN_CODE: '0105'},
{MUN_LABEL: 'Cape May Point Borough',
COUNTY: 'CAPE MAY',
MUN_CODE: '0503'},
{MUN_LABEL: 'West Cape May Borough',
COUNTY: 'CAPE MAY',
MUN_CODE: '0512'},
{MUN_LABEL: 'Cape May City',
COUNTY: 'CAPE MAY',
MUN_CODE: '0502'},
{MUN_LABEL: 'Wildwood Crest Borough',
COUNTY: 'CAPE MAY',
MUN_CODE: '0515'},
{MUN_LABEL: 'West Wildwood Borough',
COUNTY: 'CAPE MAY',
MUN_CODE: '0513'},
{MUN_LABEL: 'North Wildwood City',
COUNTY: 'CAPE MAY',
MUN_CODE: '0507'},
{MUN_LABEL: 'Lower Township',
COUNTY: 'CAPE MAY',
MUN_CODE: '0505'},
{MUN_LABEL: 'Stone Harbor Borough',
COUNTY: 'CAPE MAY',
MUN_CODE: '0510'},
{MUN_LABEL: 'Avalon Borough',
COUNTY: 'CAPE MAY',
MUN_CODE: '0501'},
{MUN_LABEL: 'Middle Township',
COUNTY: 'CAPE MAY',
MUN_CODE: '0506'},
{MUN_LABEL: 'Sea Isle City',
COUNTY: 'CAPE MAY',
MUN_CODE: '0509'},
{MUN_LABEL: 'Woodbine Borough',
COUNTY: 'CAPE MAY',
MUN_CODE: '0516'},
{MUN_LABEL: 'Dennis Township',
COUNTY: 'CAPE MAY',
MUN_CODE: '0504'},
{MUN_LABEL: 'Ocean City',
COUNTY: 'CAPE MAY',
MUN_CODE: '0508'},
{MUN_LABEL: 'Longport Borough',
COUNTY: 'ATLANTIC',
MUN_CODE: '0115'},
{MUN_LABEL: 'Upper Township',
COUNTY: 'CAPE MAY',
MUN_CODE: '0511'},
{MUN_LABEL: 'Corbin City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0106'},
{MUN_LABEL: 'Somers Point City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0121'},
{MUN_LABEL: 'Margate City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0116'},
{MUN_LABEL: 'Commercial Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0602'},
{MUN_LABEL: 'Downe Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0604'},
{MUN_LABEL: 'Ventnor City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0122'},
{MUN_LABEL: 'Lawrence Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0608'},
{MUN_LABEL: 'Atlantic City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0102'},
{MUN_LABEL: 'Greenwich Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0606'},
{MUN_LABEL: 'Weymouth Township',
COUNTY: 'ATLANTIC',
MUN_CODE: '0123'},
{MUN_LABEL: 'Linwood City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0114'},
{MUN_LABEL: 'Northfield City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0118'},
{MUN_LABEL: 'Estell Manor City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0109'},
{MUN_LABEL: 'Millville City',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0610'},
{MUN_LABEL: 'Brigantine City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0103'},
{MUN_LABEL: 'Bridgeton City',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0601'},
{MUN_LABEL: 'Shiloh Borough',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0611'},
{MUN_LABEL: 'Franklin Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0805'},
{MUN_LABEL: 'Woodstown Borough',
COUNTY: 'SALEM',
MUN_CODE: '1715'},
{MUN_LABEL: 'Upper Pittsgrove Township',
COUNTY: 'SALEM',
MUN_CODE: '1714'},
{MUN_LABEL: 'Surf City Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1532'},
{MUN_LABEL: 'Mannington Township',
COUNTY: 'SALEM',
MUN_CODE: '1706'},
{MUN_LABEL: 'Clayton Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0801'},
{MUN_LABEL: 'Long Beach Township',
COUNTY: 'OCEAN',
MUN_CODE: '1518'},
{MUN_LABEL: 'Pennsville Township',
COUNTY: 'SALEM',
MUN_CODE: '1709'},
{MUN_LABEL: 'Elk Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0804'},
{MUN_LABEL: 'Harvey Cedars Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1510'},
{MUN_LABEL: 'Eagleswood Township',
COUNTY: 'OCEAN',
MUN_CODE: '1509'},
{MUN_LABEL: 'Pilesgrove Township',
COUNTY: 'SALEM',
MUN_CODE: '1710'},
{MUN_LABEL: 'Monroe Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0811'},
{MUN_LABEL: 'South Harrison Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0816'},
{MUN_LABEL: 'Glassboro Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0806'},
{MUN_LABEL: 'Penns Grove Borough',
COUNTY: 'SALEM',
MUN_CODE: '1708'},
{MUN_LABEL: 'Chesilhurst Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0410'},
{MUN_LABEL: 'Pitman Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0815'},
{MUN_LABEL: 'Carneys Point Township',
COUNTY: 'SALEM',
MUN_CODE: '1702'},
{MUN_LABEL: 'Swedesboro Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0817'},
{MUN_LABEL: 'Woodbury Heights Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0823'},
{MUN_LABEL: 'Ocean Township',
COUNTY: 'OCEAN',
MUN_CODE: '1521'},
{MUN_LABEL: 'Lindenwold Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0422'},
{MUN_LABEL: 'Stratford Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0432'},
{MUN_LABEL: 'Shamong Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0332'},
{MUN_LABEL: 'Hi-Nella Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0419'},
{MUN_LABEL: 'Logan Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0809'},
{MUN_LABEL: 'Gibbsboro Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0413'},
{MUN_LABEL: 'Gloucester Township',
COUNTY: 'CAMDEN',
MUN_CODE: '0415'},
{MUN_LABEL: 'Greenwich Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0807'},
{MUN_LABEL: 'Woodbury City',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0822'},
{MUN_LABEL: 'Somerdale Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0431'},
{MUN_LABEL: 'Paulsboro Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0814'},
{MUN_LABEL: 'Runnemede Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0430'},
{MUN_LABEL: 'Magnolia Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0423'},
{MUN_LABEL: 'Deptford Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0802'},
{MUN_LABEL: 'Medford Lakes Borough',
COUNTY: 'BURLINGTON',
MUN_CODE: '0321'},
{MUN_LABEL: 'Bellmawr Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0404'},
{MUN_LABEL: 'Tabernacle Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0335'},
{MUN_LABEL: 'Harrison Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0808'},
{MUN_LABEL: 'Barnegat Light Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1502'},
{MUN_LABEL: 'Bass River Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0301'},
{MUN_LABEL: 'Little Egg Harbor Township',
COUNTY: 'OCEAN',
MUN_CODE: '1517'},
{MUN_LABEL: 'Washington Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0336'},
{MUN_LABEL: 'Stafford Township',
COUNTY: 'OCEAN',
MUN_CODE: '1531'},
{MUN_LABEL: 'Winslow Township',
COUNTY: 'CAMDEN',
MUN_CODE: '0436'},
{MUN_LABEL: 'Oldmans Township',
COUNTY: 'SALEM',
MUN_CODE: '1707'},
{MUN_LABEL: 'Waterford Township',
COUNTY: 'CAMDEN',
MUN_CODE: '0435'},
{MUN_LABEL: 'Woolwich Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0824'},
{MUN_LABEL: 'Mantua Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0810'},
{MUN_LABEL: 'Wenonah Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0819'},
{MUN_LABEL: 'Washington Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0818'},
{MUN_LABEL: 'Pine Hill Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0428'},
{MUN_LABEL: 'Berlin Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0405'},
{MUN_LABEL: 'Clementon Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0411'},
{MUN_LABEL: 'Laurel Springs Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0420'},
{MUN_LABEL: 'East Greenwich Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0803'},
{MUN_LABEL: 'Barnegat Township',
COUNTY: 'OCEAN',
MUN_CODE: '1501'},
{MUN_LABEL: 'Elmer Borough',
COUNTY: 'SALEM',
MUN_CODE: '1703'},
{MUN_LABEL: 'Alloway Township',
COUNTY: 'SALEM',
MUN_CODE: '1701'},
{MUN_LABEL: 'Tuckerton Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1533'},
{MUN_LABEL: 'Folsom Borough',
COUNTY: 'ATLANTIC',
MUN_CODE: '0110'},
{MUN_LABEL: 'Ship Bottom Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1529'},
{MUN_LABEL: 'Lawnside Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0421'},
{MUN_LABEL: 'National Park Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0812'},
{MUN_LABEL: 'Tavistock Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0433'},
{MUN_LABEL: 'West Deptford Township',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0820'},
{MUN_LABEL: 'Barrington Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0403'},
{MUN_LABEL: 'Brooklawn Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0407'},
{MUN_LABEL: 'Gloucester City',
COUNTY: 'CAMDEN',
MUN_CODE: '0414'},
{MUN_LABEL: 'Oaklyn Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0426'},
{MUN_LABEL: 'Cherry Hill Township',
COUNTY: 'CAMDEN',
MUN_CODE: '0409'},
{MUN_LABEL: 'Seaside Heights Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1527'},
{MUN_LABEL: 'Merchantville Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0424'},
{MUN_LABEL: 'Camden City',
COUNTY: 'CAMDEN',
MUN_CODE: '0408'},
{MUN_LABEL: 'Maple Shade Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0319'},
{MUN_LABEL: 'Pemberton Borough',
COUNTY: 'BURLINGTON',
MUN_CODE: '0328'},
{MUN_LABEL: 'Lavallette Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1516'},
{MUN_LABEL: 'Southampton Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0333'},
{MUN_LABEL: 'Berkeley Township',
COUNTY: 'OCEAN',
MUN_CODE: '1506'},
{MUN_LABEL: 'Lumberton Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0317'},
{MUN_LABEL: 'Pennsauken Township',
COUNTY: 'CAMDEN',
MUN_CODE: '0427'},
{MUN_LABEL: 'Hainesport Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0316'},
{MUN_LABEL: 'Mount Laurel Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0324'},
{MUN_LABEL: 'Pemberton Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0329'},
{MUN_LABEL: 'Mount Holly Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0323'},
{MUN_LABEL: 'Moorestown Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0322'},
{MUN_LABEL: 'Haddonfield Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0417'},
{MUN_LABEL: 'Berlin Township',
COUNTY: 'CAMDEN',
MUN_CODE: '0406'},
{MUN_LABEL: 'Woodlynne Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0437'},
{MUN_LABEL: 'Woodland Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0339'},
{MUN_LABEL: 'Collingswood Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0412'},
{MUN_LABEL: 'Haddon Township',
COUNTY: 'CAMDEN',
MUN_CODE: '0416'},
{MUN_LABEL: 'Lacey Township',
COUNTY: 'OCEAN',
MUN_CODE: '1513'},
{MUN_LABEL: 'Pine Beach Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1523'},
{MUN_LABEL: 'Ocean Gate Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1522'},
{MUN_LABEL: 'Seaside Park Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1528'},
{MUN_LABEL: 'Beachwood Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1505'},
{MUN_LABEL: 'South Toms River Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1530'},
{MUN_LABEL: 'Island Heights Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1511'},
{MUN_LABEL: 'Westville Borough',
COUNTY: 'GLOUCESTER',
MUN_CODE: '0821'},
{MUN_LABEL: 'Haddon Heights Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0418'},
{MUN_LABEL: 'Mount Ephraim Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0425'},
{MUN_LABEL: 'Audubon Park Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0402'},
{MUN_LABEL: 'Audubon Borough',
COUNTY: 'CAMDEN',
MUN_CODE: '0401'},
{MUN_LABEL: 'Willingboro Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0338'},
{MUN_LABEL: 'Mantoloking Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1520'},
{MUN_LABEL: 'Delanco Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0309'},
{MUN_LABEL: 'Beverly City',
COUNTY: 'BURLINGTON',
MUN_CODE: '0302'},
{MUN_LABEL: 'Edgewater Park Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0312'},
{MUN_LABEL: 'Springfield Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0334'},
{MUN_LABEL: 'Bay Head Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1503'},
{MUN_LABEL: 'Point Pleasant Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1525'},
{MUN_LABEL: 'Burlington City',
COUNTY: 'BURLINGTON',
MUN_CODE: '0305'},
{MUN_LABEL: 'Point Pleasant Beach Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1526'},
{MUN_LABEL: 'Lakewood Township',
COUNTY: 'OCEAN',
MUN_CODE: '1515'},
{MUN_LABEL: 'Burlington Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0306'},
{MUN_LABEL: 'Mansfield Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0318'},
{MUN_LABEL: 'Florence Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0315'},
{MUN_LABEL: 'Brick Township',
COUNTY: 'OCEAN',
MUN_CODE: '1507'},
{MUN_LABEL: 'North Hanover Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0326'},
{MUN_LABEL: 'Brielle Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1309'},
{MUN_LABEL: 'Plumsted Township',
COUNTY: 'OCEAN',
MUN_CODE: '1524'},
{MUN_LABEL: 'Sea Girt Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1343'},
{MUN_LABEL: 'Fieldsboro Borough',
COUNTY: 'BURLINGTON',
MUN_CODE: '0314'},
{MUN_LABEL: 'Bordentown City',
COUNTY: 'BURLINGTON',
MUN_CODE: '0303'},
{MUN_LABEL: 'Chesterfield Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0307'},
{MUN_LABEL: 'Spring Lake Heights Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1348'},
{MUN_LABEL: 'Spring Lake Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1347'},
{MUN_LABEL: 'Roosevelt Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1340'},
{MUN_LABEL: 'Allenhurst Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1302'},
{MUN_LABEL: 'Interlaken Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1322'},
{MUN_LABEL: 'Trenton City',
COUNTY: 'MERCER',
MUN_CODE: '1111'},
{MUN_LABEL: 'Howell Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1321'},
{MUN_LABEL: 'Deal Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1311'},
{MUN_LABEL: 'Robbinsville Township',
COUNTY: 'MERCER',
MUN_CODE: '1112'},
{MUN_LABEL: 'Jackson Township',
COUNTY: 'OCEAN',
MUN_CODE: '1512'},
{MUN_LABEL: 'Lake Como Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1346'},
{MUN_LABEL: 'Bordentown Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0304'},
{MUN_LABEL: 'Allentown Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1303'},
{MUN_LABEL: 'Belmar Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1307'},
{MUN_LABEL: 'Avon-by-the-Sea Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1306'},
{MUN_LABEL: 'Farmingdale Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1315'},
{MUN_LABEL: 'Neptune City Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1336'},
{MUN_LABEL: 'Upper Freehold Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1351'},
{MUN_LABEL: 'Asbury Park City',
COUNTY: 'MONMOUTH',
MUN_CODE: '1304'},
{MUN_LABEL: 'Loch Arbour Village',
COUNTY: 'MONMOUTH',
MUN_CODE: '1326'},
{MUN_LABEL: 'Palmyra Borough',
COUNTY: 'BURLINGTON',
MUN_CODE: '0327'},
{MUN_LABEL: 'Lakehurst Borough',
COUNTY: 'OCEAN',
MUN_CODE: '1514'},
{MUN_LABEL: 'Riverton Borough',
COUNTY: 'BURLINGTON',
MUN_CODE: '0331'},
{MUN_LABEL: 'Eastampton Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0311'},
{MUN_LABEL: 'Cinnaminson Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0308'},
{MUN_LABEL: 'Wrightstown Borough',
COUNTY: 'BURLINGTON',
MUN_CODE: '0340'},
{MUN_LABEL: 'Delran Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0310'},
{MUN_LABEL: 'Westampton Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0337'},
{MUN_LABEL: 'Riverside Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0330'},
{MUN_LABEL: 'Manchester Township',
COUNTY: 'OCEAN',
MUN_CODE: '1519'},
{MUN_LABEL: 'Toms River Township',
COUNTY: 'OCEAN',
MUN_CODE: '1508'},
{MUN_LABEL: 'Hamilton Township',
COUNTY: 'MERCER',
MUN_CODE: '1103'},
{MUN_LABEL: 'Freehold Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1316'},
{MUN_LABEL: 'Millstone Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1333'},
{MUN_LABEL: 'Ocean Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1337'},
{MUN_LABEL: 'Hightstown Borough',
COUNTY: 'MERCER',
MUN_CODE: '1104'},
{MUN_LABEL: 'Ewing Township',
COUNTY: 'MERCER',
MUN_CODE: '1102'},
{MUN_LABEL: 'Freehold Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1317'},
{MUN_LABEL: 'East Windsor Township',
COUNTY: 'MERCER',
MUN_CODE: '1101'},
{MUN_LABEL: 'Englishtown Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1313'},
{MUN_LABEL: 'West Long Branch Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1353'},
{MUN_LABEL: 'Eatontown Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1312'},
{MUN_LABEL: 'Shrewsbury Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1345'},
{MUN_LABEL: 'Long Branch City',
COUNTY: 'MONMOUTH',
MUN_CODE: '1327'},
{MUN_LABEL: 'Little Silver Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1325'},
{MUN_LABEL: 'Lawrence Township',
COUNTY: 'MERCER',
MUN_CODE: '1107'},
{MUN_LABEL: 'Jamesburg Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1208'},
{MUN_LABEL: 'Red Bank Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1339'},
{MUN_LABEL: 'Plainsboro Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1218'},
{MUN_LABEL: 'Fair Haven Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1314'},
{MUN_LABEL: 'Rocky Hill Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1817'},
{MUN_LABEL: 'Spotswood Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1224'},
{MUN_LABEL: 'Stockton Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1023'},
{MUN_LABEL: 'Hopewell Township',
COUNTY: 'MERCER',
MUN_CODE: '1106'},
{MUN_LABEL: 'West Amwell Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1026'},
{MUN_LABEL: 'Holmdel Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1320'},
{MUN_LABEL: 'Matawan Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1331'},
{MUN_LABEL: 'South Brunswick Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1221'},
{MUN_LABEL: 'Keyport Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1324'},
{MUN_LABEL: 'Middletown Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1332'},
{MUN_LABEL: 'Hazlet Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1318'},
{MUN_LABEL: 'Keansburg Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1323'},
{MUN_LABEL: 'Union Beach Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1350'},
{MUN_LABEL: 'Milltown Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1211'},
{MUN_LABEL: 'Old Bridge Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1215'},
{MUN_LABEL: 'New Brunswick City',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1213'},
{MUN_LABEL: 'Rumson Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1341'},
{MUN_LABEL: 'Lambertville City',
COUNTY: 'HUNTERDON',
MUN_CODE: '1017'},
{MUN_LABEL: 'Helmetta Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1206'},
{MUN_LABEL: 'Princeton',
COUNTY: 'MERCER',
MUN_CODE: '1114'},
{MUN_LABEL: 'Marlboro Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1330'},
{MUN_LABEL: 'Hopewell Borough',
COUNTY: 'MERCER',
MUN_CODE: '1105'},
{MUN_LABEL: 'Sea Bright Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1342'},
{MUN_LABEL: 'Pennington Borough',
COUNTY: 'MERCER',
MUN_CODE: '1108'},
{MUN_LABEL: 'Tinton Falls Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1349'},
{MUN_LABEL: 'Shrewsbury Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1344'},
{MUN_LABEL: 'Cranbury Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1202'},
{MUN_LABEL: 'Oceanport Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1338'},
{MUN_LABEL: 'West Windsor Township',
COUNTY: 'MERCER',
MUN_CODE: '1113'},
{MUN_LABEL: 'Manalapan Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1328'},
{MUN_LABEL: 'Colts Neck Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1310'},
{MUN_LABEL: 'Monmouth Beach Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1334'},
{MUN_LABEL: 'Millstone Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1812'},
{MUN_LABEL: 'Sayreville Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1219'},
{MUN_LABEL: 'Highland Park Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1207'},
{MUN_LABEL: 'Flemington Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1009'},
{MUN_LABEL: 'Delaware Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1007'},
{MUN_LABEL: 'Frenchtown Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1011'},
{MUN_LABEL: 'Perth Amboy City',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1216'},
{MUN_LABEL: 'Franklin Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1808'},
{MUN_LABEL: 'Metuchen Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1209'},
{MUN_LABEL: 'Manville Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1811'},
{MUN_LABEL: 'South Bound Brook Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1819'},
{MUN_LABEL: 'Hillsborough Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1810'},
{MUN_LABEL: 'Milford Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1020'},
{MUN_LABEL: 'Raritan Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1021'},
{MUN_LABEL: 'Somerville Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1818'},
{MUN_LABEL: 'Bound Brook Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1804'},
{MUN_LABEL: 'Raritan Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1816'},
{MUN_LABEL: 'Piscataway Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1217'},
{MUN_LABEL: 'South Plainfield Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1222'},
{MUN_LABEL: 'Carteret Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1201'},
{MUN_LABEL: 'Edison Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1205'},
{MUN_LABEL: 'Rahway City',
COUNTY: 'UNION',
MUN_CODE: '2013'},
{MUN_LABEL: 'Franklin Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1010'},
{MUN_LABEL: 'Green Brook Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1809'},
{MUN_LABEL: 'Winfield Township',
COUNTY: 'UNION',
MUN_CODE: '2021'},
{MUN_LABEL: 'Clark Township',
COUNTY: 'UNION',
MUN_CODE: '2002'},
{MUN_LABEL: 'Holland Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1015'},
{MUN_LABEL: 'North Plainfield Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1814'},
{MUN_LABEL: 'Plainfield City',
COUNTY: 'UNION',
MUN_CODE: '2012'},
{MUN_LABEL: 'Clinton Town',
COUNTY: 'HUNTERDON',
MUN_CODE: '1005'},
{MUN_LABEL: 'Alexandria Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1001'},
{MUN_LABEL: 'Bridgewater Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1806'},
{MUN_LABEL: 'Lebanon Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1018'},
{MUN_LABEL: 'Clinton Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1006'},
{MUN_LABEL: 'Berkeley Heights Township',
COUNTY: 'UNION',
MUN_CODE: '2001'},
{MUN_LABEL: 'Mountainside Borough',
COUNTY: 'UNION',
MUN_CODE: '2010'},
{MUN_LABEL: 'Bayonne City',
COUNTY: 'HUDSON',
MUN_CODE: '0901'},
{MUN_LABEL: 'Bethlehem Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1002'},
{MUN_LABEL: 'Phillipsburg Town',
COUNTY: 'WARREN',
MUN_CODE: '2119'},
{MUN_LABEL: 'Glen Gardner Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1012'},
{MUN_LABEL: 'New Providence Borough',
COUNTY: 'UNION',
MUN_CODE: '2011'},
{MUN_LABEL: 'Springfield Township',
COUNTY: 'UNION',
MUN_CODE: '2017'},
{MUN_LABEL: 'Hampton Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1013'},
{MUN_LABEL: 'Greenwich Township',
COUNTY: 'WARREN',
MUN_CODE: '2107'},
{MUN_LABEL: 'Union Township',
COUNTY: 'UNION',
MUN_CODE: '2019'},
{MUN_LABEL: 'Fanwood Borough',
COUNTY: 'UNION',
MUN_CODE: '2005'},
{MUN_LABEL: 'Linden City',
COUNTY: 'UNION',
MUN_CODE: '2009'},
{MUN_LABEL: 'Garwood Borough',
COUNTY: 'UNION',
MUN_CODE: '2006'},
{MUN_LABEL: 'Roselle Borough',
COUNTY: 'UNION',
MUN_CODE: '2014'},
{MUN_LABEL: 'Watchung Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1821'},
{MUN_LABEL: 'Bloomsbury Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1003'},
{MUN_LABEL: 'Roselle Park Borough',
COUNTY: 'UNION',
MUN_CODE: '2015'},
{MUN_LABEL: 'Warren Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1820'},
{MUN_LABEL: 'Alpha Borough',
COUNTY: 'WARREN',
MUN_CODE: '2102'},
{MUN_LABEL: 'Cranford Township',
COUNTY: 'UNION',
MUN_CODE: '2003'},
{MUN_LABEL: 'Scotch Plains Township',
COUNTY: 'UNION',
MUN_CODE: '2016'},
{MUN_LABEL: 'Union Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1025'},
{MUN_LABEL: 'High Bridge Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1014'},
{MUN_LABEL: 'Westfield Town',
COUNTY: 'UNION',
MUN_CODE: '2020'},
{MUN_LABEL: 'Pohatcong Township',
COUNTY: 'WARREN',
MUN_CODE: '2120'},
{MUN_LABEL: 'Kenilworth Borough',
COUNTY: 'UNION',
MUN_CODE: '2008'},
{MUN_LABEL: 'Elizabeth City',
COUNTY: 'UNION',
MUN_CODE: '2004'},
{MUN_LABEL: 'South River Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1223'},
{MUN_LABEL: 'Montgomery Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1813'},
{MUN_LABEL: 'North Brunswick Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1214'},
{MUN_LABEL: 'East Brunswick Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1204'},
{MUN_LABEL: 'East Amwell Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1008'},
{MUN_LABEL: 'South Amboy City',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1220'},
{MUN_LABEL: 'Lopatcong Township',
COUNTY: 'WARREN',
MUN_CODE: '2115'},
{MUN_LABEL: 'Far Hills Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1807'},
{MUN_LABEL: 'Bedminster Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1801'},
{MUN_LABEL: 'Califon Borough',
COUNTY: 'HUNTERDON',
MUN_CODE: '1004'},
{MUN_LABEL: 'Summit City',
COUNTY: 'UNION',
MUN_CODE: '2018'},
{MUN_LABEL: 'Peapack-Gladstone Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1815'},
{MUN_LABEL: 'Tewksbury Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1024'},
{MUN_LABEL: 'Bernardsville Borough',
COUNTY: 'SOMERSET',
MUN_CODE: '1803'},
{MUN_LABEL: 'Hoboken City',
COUNTY: 'HUDSON',
MUN_CODE: '0905'},
{MUN_LABEL: 'Maplewood Township',
COUNTY: 'ESSEX',
MUN_CODE: '0711'},
{MUN_LABEL: 'Millburn Township',
COUNTY: 'ESSEX',
MUN_CODE: '0712'},
{MUN_LABEL: 'Weehawken Township',
COUNTY: 'HUDSON',
MUN_CODE: '0911'},
{MUN_LABEL: 'North Arlington Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0239'},
{MUN_LABEL: 'Secaucus Town',
COUNTY: 'HUDSON',
MUN_CODE: '0909'},
{MUN_LABEL: 'Belleville Township',
COUNTY: 'ESSEX',
MUN_CODE: '0701'},
{MUN_LABEL: 'Livingston Township',
COUNTY: 'ESSEX',
MUN_CODE: '0710'},
{MUN_LABEL: 'Lyndhurst Township',
COUNTY: 'BERGEN',
MUN_CODE: '0232'},
{MUN_LABEL: 'North Bergen Township',
COUNTY: 'HUDSON',
MUN_CODE: '0908'},
{MUN_LABEL: 'West Orange Township',
COUNTY: 'ESSEX',
MUN_CODE: '0722'},
{MUN_LABEL: 'Fairview Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0218'},
{MUN_LABEL: 'Glen Ridge Borough',
COUNTY: 'ESSEX',
MUN_CODE: '0708'},
{MUN_LABEL: 'Union City',
COUNTY: 'HUDSON',
MUN_CODE: '0910'},
{MUN_LABEL: 'Kearny Town',
COUNTY: 'HUDSON',
MUN_CODE: '0907'},
{MUN_LABEL: 'Lebanon Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1019'},
{MUN_LABEL: 'East Orange City',
COUNTY: 'ESSEX',
MUN_CODE: '0705'},
{MUN_LABEL: 'City of Orange Township',
COUNTY: 'ESSEX',
MUN_CODE: '0717'},
{MUN_LABEL: 'Harmony Township',
COUNTY: 'WARREN',
MUN_CODE: '2110'},
{MUN_LABEL: 'West New York Town',
COUNTY: 'HUDSON',
MUN_CODE: '0912'},
{MUN_LABEL: 'Guttenberg Town',
COUNTY: 'HUDSON',
MUN_CODE: '0903'},
{MUN_LABEL: 'Washington Township',
COUNTY: 'WARREN',
MUN_CODE: '2122'},
{MUN_LABEL: 'South Orange Village Township',
COUNTY: 'ESSEX',
MUN_CODE: '0719'},
{MUN_LABEL: 'Franklin Township',
COUNTY: 'WARREN',
MUN_CODE: '2105'},
{MUN_LABEL: 'Washington Borough',
COUNTY: 'WARREN',
MUN_CODE: '2121'},
{MUN_LABEL: 'Caldwell Borough',
COUNTY: 'ESSEX',
MUN_CODE: '0703'},
{MUN_LABEL: 'Edgewater Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0213'},
{MUN_LABEL: 'Bloomfield Township',
COUNTY: 'ESSEX',
MUN_CODE: '0702'},
{MUN_LABEL: 'Verona Township',
COUNTY: 'ESSEX',
MUN_CODE: '0720'},
{MUN_LABEL: 'Carlstadt Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0205'},
{MUN_LABEL: 'East Rutherford Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0212'},
{MUN_LABEL: 'Moonachie Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0237'},
{MUN_LABEL: 'Palisades Park Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0245'},
{MUN_LABEL: 'Mansfield Township',
COUNTY: 'WARREN',
MUN_CODE: '2116'},
{MUN_LABEL: 'Little Ferry Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0230'},
{MUN_LABEL: 'Montclair Township',
COUNTY: 'ESSEX',
MUN_CODE: '0713'},
{MUN_LABEL: 'Wallington Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0265'},
{MUN_LABEL: 'Wood-Ridge Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0269'},
{MUN_LABEL: 'West Caldwell Township',
COUNTY: 'ESSEX',
MUN_CODE: '0721'},
{MUN_LABEL: 'Ridgefield Park Village',
COUNTY: 'BERGEN',
MUN_CODE: '0250'},
{MUN_LABEL: 'Fort Lee Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0219'},
{MUN_LABEL: 'South Hackensack Township',
COUNTY: 'BERGEN',
MUN_CODE: '0259'},
{MUN_LABEL: 'Hasbrouck Heights Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0225'},
{MUN_LABEL: 'White Township',
COUNTY: 'WARREN',
MUN_CODE: '2123'},
{MUN_LABEL: 'Passaic City',
COUNTY: 'PASSAIC',
MUN_CODE: '1607'},
{MUN_LABEL: 'Leonia Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0229'},
{MUN_LABEL: 'Cedar Grove Township',
COUNTY: 'ESSEX',
MUN_CODE: '0704'},
{MUN_LABEL: 'Stanhope Borough',
COUNTY: 'SUSSEX',
MUN_CODE: '1919'},
{MUN_LABEL: 'Independence Township',
COUNTY: 'WARREN',
MUN_CODE: '2112'},
{MUN_LABEL: 'Tenafly Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0261'},
{MUN_LABEL: 'Bergenfield Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0203'},
{MUN_LABEL: 'Paterson City',
COUNTY: 'PASSAIC',
MUN_CODE: '1608'},
{MUN_LABEL: 'River Edge Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0252'},
{MUN_LABEL: 'Hope Township',
COUNTY: 'WARREN',
MUN_CODE: '2111'},
{MUN_LABEL: 'Prospect Park Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1610'},
{MUN_LABEL: 'Haledon Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1603'},
{MUN_LABEL: 'New Milford Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0238'},
{MUN_LABEL: 'Fair Lawn Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0217'},
{MUN_LABEL: 'North Caldwell Borough',
COUNTY: 'ESSEX',
MUN_CODE: '0715'},
{MUN_LABEL: 'Hackettstown Town',
COUNTY: 'WARREN',
MUN_CODE: '2108'},
{MUN_LABEL: 'Bogota Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0204'},
{MUN_LABEL: 'Little Falls Township',
COUNTY: 'PASSAIC',
MUN_CODE: '1605'},
{MUN_LABEL: 'Garfield City',
COUNTY: 'BERGEN',
MUN_CODE: '0221'},
{MUN_LABEL: 'Lodi Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0231'},
{MUN_LABEL: 'Liberty Township',
COUNTY: 'WARREN',
MUN_CODE: '2114'},
{MUN_LABEL: 'Englewood Cliffs Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0216'},
{MUN_LABEL: 'Fairfield Township',
COUNTY: 'ESSEX',
MUN_CODE: '0707'},
{MUN_LABEL: 'Byram Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1904'},
{MUN_LABEL: 'Hackensack City',
COUNTY: 'BERGEN',
MUN_CODE: '0223'},
{MUN_LABEL: 'Teaneck Township',
COUNTY: 'BERGEN',
MUN_CODE: '0260'},
{MUN_LABEL: 'Maywood Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0234'},
{MUN_LABEL: 'Rochelle Park Township',
COUNTY: 'BERGEN',
MUN_CODE: '0254'},
{MUN_LABEL: 'Saddle Brook Township',
COUNTY: 'BERGEN',
MUN_CODE: '0257'},
{MUN_LABEL: 'Englewood City',
COUNTY: 'BERGEN',
MUN_CODE: '0215'},
{MUN_LABEL: 'Elmwood Park Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0211'},
{MUN_LABEL: 'Totowa Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1612'},
{MUN_LABEL: 'Oxford Township',
COUNTY: 'WARREN',
MUN_CODE: '2117'},
{MUN_LABEL: 'Essex Fells Borough',
COUNTY: 'ESSEX',
MUN_CODE: '0706'},
{MUN_LABEL: 'Cliffside Park Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0206'},
{MUN_LABEL: 'Nutley Township',
COUNTY: 'ESSEX',
MUN_CODE: '0716'},
{MUN_LABEL: 'Roseland Borough',
COUNTY: 'ESSEX',
MUN_CODE: '0718'},
{MUN_LABEL: 'Belvidere Town',
COUNTY: 'WARREN',
MUN_CODE: '2103'},
{MUN_LABEL: 'Rutherford Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0256'},
{MUN_LABEL: 'Ridgefield Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0249'},
{MUN_LABEL: 'Cresskill Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0208'},
{MUN_LABEL: 'Dumont Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0210'},
{MUN_LABEL: 'Allamuchy Township',
COUNTY: 'WARREN',
MUN_CODE: '2101'},
{MUN_LABEL: 'Hawthorne Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1604'},
{MUN_LABEL: 'Closter Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0207'},
{MUN_LABEL: 'Emerson Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0214'},
{MUN_LABEL: 'Knowlton Township',
COUNTY: 'WARREN',
MUN_CODE: '2113'},
{MUN_LABEL: 'Hopatcong Borough',
COUNTY: 'SUSSEX',
MUN_CODE: '1912'},
{MUN_LABEL: 'Andover Borough',
COUNTY: 'SUSSEX',
MUN_CODE: '1901'},
{MUN_LABEL: 'Westwood Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0267'},
{MUN_LABEL: 'Washington Township',
COUNTY: 'BERGEN',
MUN_CODE: '0266'},
{MUN_LABEL: 'Green Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1908'},
{MUN_LABEL: 'Franklin Lakes Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0220'},
{MUN_LABEL: 'River Vale Township',
COUNTY: 'BERGEN',
MUN_CODE: '0253'},
{MUN_LABEL: 'Allendale Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0201'},
{MUN_LABEL: 'Woodcliff Lake Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0268'},
{MUN_LABEL: 'Park Ridge Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0247'},
{MUN_LABEL: 'Saddle River Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0258'},
{MUN_LABEL: 'Alpine Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0202'},
{MUN_LABEL: 'Harrington Park Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0224'},
{MUN_LABEL: 'Ridgewood Village',
COUNTY: 'BERGEN',
MUN_CODE: '0251'},
{MUN_LABEL: 'Midland Park Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0235'},
{MUN_LABEL: 'Ho-Ho-Kus Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0228'},
{MUN_LABEL: 'Norwood Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0241'},
{MUN_LABEL: 'Wayne Township',
COUNTY: 'PASSAIC',
MUN_CODE: '1614'},
{MUN_LABEL: 'Rockleigh Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0255'},
{MUN_LABEL: 'Frelinghuysen Township',
COUNTY: 'WARREN',
MUN_CODE: '2106'},
{MUN_LABEL: 'Hillsdale Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0227'},
{MUN_LABEL: 'Waldwick Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0264'},
{MUN_LABEL: 'Northvale Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0240'},
{MUN_LABEL: 'Wyckoff Township',
COUNTY: 'BERGEN',
MUN_CODE: '0270'},
{MUN_LABEL: 'Old Tappan Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0243'},
{MUN_LABEL: 'Blairstown Township',
COUNTY: 'WARREN',
MUN_CODE: '2104'},
{MUN_LABEL: 'Demarest Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0209'},
{MUN_LABEL: 'Oradell Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0244'},
{MUN_LABEL: 'Haworth Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0226'},
{MUN_LABEL: 'Glen Rock Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0222'},
{MUN_LABEL: 'North Haledon Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1606'},
{MUN_LABEL: 'Paramus Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0246'},
{MUN_LABEL: 'Upper Saddle River Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0263'},
{MUN_LABEL: 'Ogdensburg Borough',
COUNTY: 'SUSSEX',
MUN_CODE: '1916'},
{MUN_LABEL: 'Hardwick Township',
COUNTY: 'WARREN',
MUN_CODE: '2109'},
{MUN_LABEL: 'Sparta Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1918'},
{MUN_LABEL: 'Mahwah Township',
COUNTY: 'BERGEN',
MUN_CODE: '0233'},
{MUN_LABEL: 'Franklin Borough',
COUNTY: 'SUSSEX',
MUN_CODE: '1906'},
{MUN_LABEL: 'Branchville Borough',
COUNTY: 'SUSSEX',
MUN_CODE: '1903'},
{MUN_LABEL: 'Hamburg Borough',
COUNTY: 'SUSSEX',
MUN_CODE: '1909'},
{MUN_LABEL: 'Lafayette Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1913'},
{MUN_LABEL: 'Ringwood Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1611'},
{MUN_LABEL: 'Walpack Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1923'},
{MUN_LABEL: 'Sussex Borough',
COUNTY: 'SUSSEX',
MUN_CODE: '1921'},
{MUN_LABEL: 'Frankford Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1905'},
{MUN_LABEL: 'Sandyston Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1917'},
{MUN_LABEL: 'Wantage Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1924'},
{MUN_LABEL: 'Bradley Beach Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1308'},
{MUN_LABEL: 'Hillside Township',
COUNTY: 'UNION',
MUN_CODE: '2007'},
{MUN_LABEL: 'Mendham Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1418'},
{MUN_LABEL: 'Chester Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1406'},
{MUN_LABEL: 'East Hanover Township',
COUNTY: 'MORRIS',
MUN_CODE: '1410'},
{MUN_LABEL: 'Washington Township',
COUNTY: 'MORRIS',
MUN_CODE: '1438'},
{MUN_LABEL: 'Randolph Township',
COUNTY: 'MORRIS',
MUN_CODE: '1432'},
{MUN_LABEL: 'Mine Hill Township',
COUNTY: 'MORRIS',
MUN_CODE: '1420'},
{MUN_LABEL: 'Dover Town',
COUNTY: 'MORRIS',
MUN_CODE: '1409'},
{MUN_LABEL: 'Morris Plains Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1423'},
{MUN_LABEL: 'Victory Gardens Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1437'},
{MUN_LABEL: 'Mountain Lakes Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1425'},
{MUN_LABEL: 'Harrison Town',
COUNTY: 'HUDSON',
MUN_CODE: '0904'},
{MUN_LABEL: 'East Newark Borough',
COUNTY: 'HUDSON',
MUN_CODE: '0902'},
{MUN_LABEL: 'Irvington Township',
COUNTY: 'ESSEX',
MUN_CODE: '0709'},
{MUN_LABEL: 'Newark City',
COUNTY: 'ESSEX',
MUN_CODE: '0714'},
{MUN_LABEL: 'Jersey City',
COUNTY: 'HUDSON',
MUN_CODE: '0906'},
{MUN_LABEL: 'Newton Town',
COUNTY: 'SUSSEX',
MUN_CODE: '1915'},
{MUN_LABEL: 'Hampton Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1910'},
{MUN_LABEL: 'Stillwater Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1920'},
{MUN_LABEL: 'Kingwood Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1016'},
{MUN_LABEL: 'Readington Township',
COUNTY: 'HUNTERDON',
MUN_CODE: '1022'},
{MUN_LABEL: 'Long Hill Township',
COUNTY: 'MORRIS',
MUN_CODE: '1430'},
{MUN_LABEL: 'Chatham Township',
COUNTY: 'MORRIS',
MUN_CODE: '1405'},
{MUN_LABEL: 'Madison Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1417'},
{MUN_LABEL: 'Harding Township',
COUNTY: 'MORRIS',
MUN_CODE: '1413'},
{MUN_LABEL: 'Morristown Town',
COUNTY: 'MORRIS',
MUN_CODE: '1424'},
{MUN_LABEL: 'Mendham Township',
COUNTY: 'MORRIS',
MUN_CODE: '1419'},
{MUN_LABEL: 'Morris Township',
COUNTY: 'MORRIS',
MUN_CODE: '1422'},
{MUN_LABEL: 'Chester Township',
COUNTY: 'MORRIS',
MUN_CODE: '1407'},
{MUN_LABEL: 'Oakland Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0242'},
{MUN_LABEL: 'Bloomingdale Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1601'},
{MUN_LABEL: 'Fredon Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1907'},
{MUN_LABEL: 'Montvale Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0236'},
{MUN_LABEL: 'Ramsey Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0248'},
{MUN_LABEL: 'Andover Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1902'},
{MUN_LABEL: 'Rockaway Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1434'},
{MUN_LABEL: 'Wharton Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1439'},
{MUN_LABEL: 'Boonton Town',
COUNTY: 'MORRIS',
MUN_CODE: '1401'},
{MUN_LABEL: 'Mount Olive Township',
COUNTY: 'MORRIS',
MUN_CODE: '1427'},
{MUN_LABEL: 'Denville Township',
COUNTY: 'MORRIS',
MUN_CODE: '1408'},
{MUN_LABEL: 'Pleasantville City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0119'},
{MUN_LABEL: 'Egg Harbor Township',
COUNTY: 'ATLANTIC',
MUN_CODE: '0108'},
{MUN_LABEL: 'Teterboro Borough',
COUNTY: 'BERGEN',
MUN_CODE: '0262'},
{MUN_LABEL: 'Egg Harbor City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0107'},
{MUN_LABEL: 'Hammonton Town',
COUNTY: 'ATLANTIC',
MUN_CODE: '0113'},
{MUN_LABEL: 'Mullica Township',
COUNTY: 'ATLANTIC',
MUN_CODE: '0117'},
{MUN_LABEL: 'Bernards Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1802'},
{MUN_LABEL: 'Wall Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1352'},
{MUN_LABEL: 'Aberdeen Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1301'},
{MUN_LABEL: 'Woodbridge Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1225'},
{MUN_LABEL: 'Stow Creek Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0612'},
{MUN_LABEL: 'Fairfield Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0605'},
{MUN_LABEL: 'Manasquan Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1329'},
{MUN_LABEL: 'Hardyston Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1911'},
{MUN_LABEL: 'West Milford Township',
COUNTY: 'PASSAIC',
MUN_CODE: '1615'},
{MUN_LABEL: 'Vernon Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1922'},
{MUN_LABEL: 'Monroe Township',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1212'},
{MUN_LABEL: 'Hanover Township',
COUNTY: 'MORRIS',
MUN_CODE: '1412'},
{MUN_LABEL: 'Parsippany-Troy Hills Township',
COUNTY: 'MORRIS',
MUN_CODE: '1429'},
{MUN_LABEL: 'Maurice River Township',
COUNTY: 'CUMBERLAND',
MUN_CODE: '0609'},
{MUN_LABEL: 'Pittsgrove Township',
COUNTY: 'SALEM',
MUN_CODE: '1711'},
{MUN_LABEL: 'Netcong Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1428'},
{MUN_LABEL: 'Voorhees Township',
COUNTY: 'CAMDEN',
MUN_CODE: '0434'},
{MUN_LABEL: 'Evesham Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0313'},
{MUN_LABEL: 'Florham Park Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1411'},
{MUN_LABEL: 'New Hanover Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0325'},
{MUN_LABEL: 'Galloway Township',
COUNTY: 'ATLANTIC',
MUN_CODE: '0111'},
{MUN_LABEL: 'Wildwood City',
COUNTY: 'CAPE MAY',
MUN_CODE: '0514'},
{MUN_LABEL: 'Medford Township',
COUNTY: 'BURLINGTON',
MUN_CODE: '0320'},
{MUN_LABEL: 'Riverdale Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1433'},
{MUN_LABEL: 'Kinnelon Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1415'},
{MUN_LABEL: 'Highlands Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1319'},
{MUN_LABEL: 'Atlantic Highlands Borough',
COUNTY: 'MONMOUTH',
MUN_CODE: '1305'},
{MUN_LABEL: 'Dunellen Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1203'},
{MUN_LABEL: 'Middlesex Borough',
COUNTY: 'MIDDLESEX',
MUN_CODE: '1210'},
{MUN_LABEL: 'Chatham Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1404'},
{MUN_LABEL: 'Neptune Township',
COUNTY: 'MONMOUTH',
MUN_CODE: '1335'},
{MUN_LABEL: 'Montague Township',
COUNTY: 'SUSSEX',
MUN_CODE: '1914'},
{MUN_LABEL: 'Pompton Lakes Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1609'},
{MUN_LABEL: 'Wanaque Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1613'},
{MUN_LABEL: 'Woodland Park Borough',
COUNTY: 'PASSAIC',
MUN_CODE: '1616'},
{MUN_LABEL: 'Clifton City',
COUNTY: 'PASSAIC',
MUN_CODE: '1602'},
{MUN_LABEL: 'Roxbury Township',
COUNTY: 'MORRIS',
MUN_CODE: '1436'},
{MUN_LABEL: 'Mount Arlington Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1426'},
{MUN_LABEL: 'Lincoln Park Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1416'},
{MUN_LABEL: 'Boonton Township',
COUNTY: 'MORRIS',
MUN_CODE: '1402'},
{MUN_LABEL: 'Montville Township',
COUNTY: 'MORRIS',
MUN_CODE: '1421'},
{MUN_LABEL: 'Pequannock Township',
COUNTY: 'MORRIS',
MUN_CODE: '1431'},
{MUN_LABEL: 'Butler Borough',
COUNTY: 'MORRIS',
MUN_CODE: '1403'},
{MUN_LABEL: 'Rockaway Township',
COUNTY: 'MORRIS',
MUN_CODE: '1435'},
{MUN_LABEL: 'Jefferson Township',
COUNTY: 'MORRIS',
MUN_CODE: '1414'},
{MUN_LABEL: 'Absecon City',
COUNTY: 'ATLANTIC',
MUN_CODE: '0101'},
{MUN_LABEL: 'Branchburg Township',
COUNTY: 'SOMERSET',
MUN_CODE: '1805'},]
    
    export default munis