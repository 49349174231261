import styled from "styled-components";
import counties from "../../data/counties";
import munis from "./munis";
import GeneralStyles from "../../GeneralStyles";

const Style = {
    FormLabel: styled.label`
        display: block;
        font-size: 1.6rem;
        margin: 0.5rem;
        text-transform: capitalize;
        letter-spacing: 1px;
        text-align: left;
    `,
    SelectCont: styled.div`
    display: flex;
    align-items: center;
    margin: 3rem;
    & > select {
        padding: 1rem;
    }
    `,
    DropsCont: styled.div`
    text-align: left; 
    margin: 3rem; 
    display: flex;
    max-width: 80rem; 
    align-items: center;
    flex-wrap: wrap;
    `,

}
const LocationSection = ({ county, setCounty, muni, setMuni }) => {

    return (
        <GeneralStyles.SectionCont>
            <h2>Select a location</h2>
            <Style.DropsCont>
                <Style.SelectCont>
                    <Style.FormLabel htmlFor='county'>
                        County
                    </Style.FormLabel>
                    <select
                        name='county'
                        id='county'
                        value={county}
                        onChange={({ target }) => setCounty(target.value)}
                    >
                        <option value={''}>Please select a county</option>
                        {counties.sort((a, b) => a.localeCompare(b)).map((county) => {
                            return <option value={county} key={county}>{county}</option>;
                        })}
                    </select>
                </Style.SelectCont>
                <Style.SelectCont>
                    <Style.FormLabel htmlFor='muni'>
                        Municipality
                    </Style.FormLabel>
                    <select
                        name='muni'
                        id='muni'
                        value={muni}
                        onChange={({ target }) => setMuni(target.value)}
                        disabled={!county}
                    >
                        <option value={''}>Select a location</option>
                        {munis.filter(item => item.COUNTY === county?.toUpperCase()).sort((a, b) => a.MUN_LABEL.localeCompare(b.MUN_LABEL)).map((muni) => {
                            return <option key={muni.MUN_CODE}>{muni.MUN_LABEL}</option>;
                        })}
                    </select>
                </Style.SelectCont>
            </Style.DropsCont>
        </GeneralStyles.SectionCont>
    )
}
export default LocationSection