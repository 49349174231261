import { useContext } from "react";
import AppContext from "../AppContext";
import { rcp } from "../data/variables";
import RadioWraps from "./radioInputs/RadioWraps";

const RcpRadios = () => {

    const [state, dispatch] = useContext(AppContext)

    let rcpElems = rcp.map((item, index) => (
        <RadioWraps
            name={item.scenario}
            text={item.scenario}
            key={index}
            active={state.activeRcp.chartVar === item.chartVar}
            handleClick={() => {
                const action = { type: 'SET_ACTIVE_RCP', payload: item }
                dispatch(action);
            }}
        />
    ))

    return rcpElems
}
export default RcpRadios