import Page from "../components/PageTemplate/Page"
import { useLocation } from "react-router-dom"
import useCheckPath from "../tools/useCheckPath"

const Mint = () => {
    //** sync topic state with path */
    let { pathname } = useLocation()
    useCheckPath(pathname)

    return (
        <Page />
    )
}
export default Mint