import React from 'react'
import styled from 'styled-components'
import radio from './radio.svg'

const RadioWraps = ({ name, text, active, handleClick }) => {
  return (
    <Styles.FormControl>
      <input type='radio' name={name} checked={active} onChange={handleClick}/>
      {text}
    </Styles.FormControl>
  )
}

export default RadioWraps

const Styles = {
  FormControl: styled.label`
    font-size: inherit;
    font-weight: 400;
    line-height: 1.1;
    margin-top: 1rem;

    display: grid;
    grid-template-columns: 1rem auto;
    gap: 1.5rem;

    // input:where([type="radio"]){
    //   -webkit-appearance : none;
    //   appearance         : none;
    //   width              : 2rem;
    //   height             : 2rem;
    //   margin             : calc(0.75em - 11px) 0.25rem 0 0;
    //   vertical-align     : top;
    //   border             : 2px solid ${({theme}) => theme.RUTeal};
    //   border-radius      : 50%;
    //   background         : #fff no-repeat center center;
    // }
    
    // input[type="radio"]{
    //   border-radius : 50%;
    // }

    // input:where([type="checkbox"], [type="radio"]):where(:active:not(:disabled), :focus){
    //   border-color : ${({theme}) => theme.RUTeal}
    //   outline      : none;
    // }
    // input[type="checkbox"]:checked{
    //   background-image : url('checkbox.svg');
    // }
    
    // input[type="radio"]:checked{
    //   background-image : url(${radio});
    // }
   
  `,
}