import { useContext } from "react";
import pagesArray from "../data/pages";
import TopicButton from "./TopicButton";
import AppContext from '../AppContext'
import { useNavigate } from "react-router-dom";


const TopicBar = () => {

    const navigate = useNavigate()

    const [state] = useContext(AppContext)

    let topicElements = pagesArray.map((topic) => {

        return (
            <TopicButton
                key={topic.id}
                // handleClick={handleTopicChange}
                handleClick={() => navigate(`/${topic.param}`)}
                active={state.activeTopic === topic.tileTitle}
                source={topic.tileImage}
                text={topic.tileTitle}
            />
        )
    }
    );

    return <>{topicElements}</>

}
export default TopicBar