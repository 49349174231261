import React from "react";
import Switch from "react-switch";

const ToggleSwitch = ({ change, onChange }) => {
  return (
    <label className="toggle-switch-label" htmlFor="toggleSwitch">
      <Switch
        checked={change}
        onChange={onChange}
        onColor="#ddd"
        onHandleColor="#00626d"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        className="react-switch"
        id="material-switch"
      />
    </label>
  );
};

export default React.memo(ToggleSwitch);
