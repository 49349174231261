import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import AppContext from "../../AppContext"
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch"
import ScenarioBlurb from "../ScenarioBlurb"
import mapIds from "../InsetMap/mapIds";
import InsetMap from "../InsetMap/InsetMap"

let Styles = {
    MapViz: styled.section`
         display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          flex-direction: column;
          width: 66%;
          margin-top: 3rem;
          margin-bottom: 5rem;
          `,
    ChangeSelector: styled.div`
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          font-size: 1.5rem;
          text-align: center;
          `,
    MapCont: styled.div`
          display: flex;
          justify-content: center;
          align-items: flex-start;
          img {
            width: 400px;
    }
          @media screen and (max-width: ${({ theme }) => theme.mobile}) {
            display: flex;
          flex-wrap: wrap;
          img {
            width: 300px;
      }
    }
          `,
}

const MapSection = () => {

    const [state, dispatch] = useContext(AppContext)


    useEffect(() => {
        // console.log('running use effect')
        const getCurrentIds = () => {
            //eslint-disable-next-line
            let activeParam = state.change ? `${state.activeParam.param}Change` : state.activeParam.param
            //eslint-disable-next-line
            let portalId = mapIds[activeParam].id
            // console.log('test id', portalId)
            //eslint-disable-next-line
            let layerId = mapIds[activeParam][state.activeRcp.chartVar][state.activeYear.chartVar][state.activeSeason.chartVar].layerId
            //eslint-disable-next-line
            let title = state.change ? `Change from baseline ${state.activeRcp.scenario} ${state.activeYear.year} ${state.activeSeason.name}` : `${state.activeRcp.scenario} ${state.activeYear.year} ${state.activeSeason.name}`
            return { portalId, layerId, title }
        }
        let { portalId, layerId, title } = getCurrentIds()
        setLayerId(layerId)
        setPortalId(portalId)
        setTitle(title)
    }, [state])


    const [portalId, setPortalId] = useState('272808b2ba0144458c899950d99b089e')
    const [layerId, setLayerId] = useState(6)
    const [title, setTitle] = useState('Change from baseline Moderate Emissions 2021-2040 Winter')


    return (
        <Styles.MapViz>
            <Styles.ChangeSelector>
                <h3>{state.activeParam?.mapTitle}</h3>
                {state.activeYear.chartVar !== 2020 && (
                    <>
                        <ToggleSwitch
                            change={state.change}
                            onChange={() => {
                                const action = { type: 'TOGGLE_CHANGE' }
                                dispatch(action);
                            }}
                        />
                        <h3> Change from 2001-2020 Baseline</h3>
                    </>
                )}
            </Styles.ChangeSelector>
            <Styles.MapCont>
                <InsetMap portalId={portalId} layerId={layerId} title={title} />
                <div className="viz-legend">
                    <div>
                        <ScenarioBlurb rcp={state.activeRcp.chartVar} />
                    </div>
                </div>
            </Styles.MapCont>
        </Styles.MapViz>
    )
}
export default MapSection