import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import { FaRegQuestionCircle } from 'react-icons/fa'
// import { theme } from './theme'

const Welcome = ({ modalStatus = true }) => {

    const modalRef = useRef();
    const [showModal, setShowModal] = useState(modalStatus)

    const openModal = () => {
        setShowModal(prev => !prev)
    }

    // if you press escape key closes modal
    const keyPress = useCallback(evt => {
        if (evt.key === 'Escape' && showModal) {
            setShowModal(false)
        }
    }, [setShowModal, showModal])

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress)
    }, [keyPress])

    useEffect(() => {
        if (showModal) {
            setShowModal(localStorage.getItem('showWelcome'))
        }
        // eslint-disable-next-line
    }, [])

    // click in background to close modal
    const closeModal = evt => {
        modalRef.current === evt.target && setShowModal(false)
    }

    const handleCheckbox = ({ target }) => {
        let { checked } = target

        localStorage.setItem('showWelcome', !checked)
    }


    return (
        <>
            <FaRegQuestionCircle
                style={{ margin: '0 1rem' }}
                color="white"
                onClick={openModal}
            />
            {showModal?.toString() === 'true' && (
                <Styles.Background ref={modalRef} onClick={closeModal}>

                    <Styles.ModalWrapper showModal={showModal}>

                        <Styles.ModalContent>


                            <p>The NJADAPT Climate Dashboard visualizes climate change trends and statistics for the whole of New Jersey. It compares today's conditions to future projections of climate using the average of 32 climate models and can be visualized in both map and interactive chart formats. The projected changes are summarized in seasonal (winter, spring, summer, fall)  intervals for six different climate measurements. Projected changes may be visualized for moderate greenhouse gas emissions (consistent with today's global policies) and high greenhouse gas emissions (continued growth of emissions by 2100). Each page contains a brief a description of its climate measurement and a summary of the projected changes to that measurement through 2100 to help interpretation. </p>

                            <label htmlFor='showWelcome'><input type="checkbox" id='showWelcome' name='showWelcome' onChange={handleCheckbox} /> Please don't show this next time I visit the site.</label>

                        </Styles.ModalContent>

                        <Styles.CloseModalButton aria-label='close modal' onClick={() => setShowModal(false)} />
                    </Styles.ModalWrapper>

                </Styles.Background>
            )}
        </>
    )
}

export default Welcome

const Styles = {

    Background: styled.div`
    width: 100vw;
    height: 100vh;
    background: #00000080;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    
    `,
    ModalWrapper: styled.div`
    width: 80rem;
    box-shadow: 0 5px 16px rgba(0,0,0,0.2);
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: 1s alternate fadein;
  @keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 100;
    }
  };
  
    `,
    ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 2rem;
    line-height: 1.8;
    color: ${({ theme }) => theme.RUTeal};
    background: #f1f1f1;
    overflowY: scroll;
    h1 {
        font-size: 4rem;
        font-weight: 300;
    }

    p {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        font-weight: 500;
    }
    
    `,

    CloseModalButton: styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    // padding 1rem;
    z-index: 10;
    // margin: 1rem;
    `,

}