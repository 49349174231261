import React from "react";
import styled from "styled-components";

const Styles = {
  Container: styled.div`
    margin: 0 2rem;
    `,
}

const ScenarioBlurb = ({ rcp }) => {
  return (
    <Styles.Container>
      This visualization is showing <span style={{ fontWeight: 'bold' }}>{rcp === 'rcp85' ? 'high emissions' : 'moderate emissions'}</span>, corresponding to a future consistent with {rcp === 'rcp85' ? 'the strong, continued growth of fossil fuel consumption.' : 'a reduction in fossil fuel consumption.'}
    </Styles.Container>
  )
};

export default ScenarioBlurb;
