const counties = [
    'Atlantic',
    'Bergen',
    'Burlington',
    'Camden',
    'Cape May',
    'Cumberland',
    'Essex',
    'Gloucester',
    'Hudson',
    'Hunterdon',
    'Mercer',
    'Monmouth',
    'Ocean',
    'Passaic',
    'Salem',
    'Somerset',
    'Sussex',
    'Union',
    'Warren',
    'Morris',
    'Middlesex',
]

export default counties