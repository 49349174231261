import React from 'react'
import './dataTable.css'

const DataTable = ({ data, header, topCaption, bottomCaption }) => {
  // console.log('table data', Object.keys(data[0]))
  let tableHeader = header ?? Object.keys(data[0])
  let headerElems = tableHeader.map((item, i) => {
    let elem
    if (typeof item !== 'object') {
      elem = <th key={i}>{item}</th>
    } else if (Object.keys(item).includes('colspan')) {
      elem = <th key={i} colSpan={item.colspan}>{item.name}</th>
    }
    return elem
  })

  let tableElems

  if (typeof data[0] !== 'object') {

    tableElems = <tr>
      {data.map((item, i) => (<td key={i}>{item}</td>))}
    </tr>
  } else {

    tableElems = data.map((item, i) => {

      return (<tr key={i}>
        {Object.values(item).map((value, i) => {

          return (<td key={i}>{value}</td>)
        })}
      </tr>)
    }
    )
  }

  return (
    <table className='data-table'>
      <caption style={{ textAlign: 'left' }}>{topCaption}</caption>
      <thead>
        <tr>
          {headerElems}
        </tr>
      </thead>
      <tbody>
        {tableElems}
      </tbody>
    </table>
  )
}

export default DataTable