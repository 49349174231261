import { useContext, useEffect } from "react"
import AppContext from "../AppContext"

const useCheckPath = (pathname) => {

    const [state, dispatch] = useContext(AppContext)

    let splitArray = pathname.split('/')
    let topic = splitArray[splitArray.length - 1]

    useEffect(() => {
        if (state.activeParam.param !== topic) {
            const action = { type: 'SET_ACTIVE_PARAM', payload: topic }
            dispatch(action);
        }
    }, [state.activeParam, dispatch, topic])

}
export default useCheckPath