import { theme } from "./components/theme";
import pagesArray from "./data/pages"

let heatParams = ["maxt", "cdd", "gdd"];

const initialState = {
    activeParam: {
        id: 1,
        tileTitle: "Mean Seasonal Precipitation",
        param: "pcpn",
        tileImage:
            "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/Precipitation-icon.png",
        title:
            "Climate Change Impacts on Mean Seasonal Precipitation in New Jersey ",
        what: "More about mean seasonal precipitation",
        description: `With climate change, warmer temperatures allow the atmosphere to hold more water vapor, potentially increasing seasonal precipitation. In recent decades, the annual precipitation in New Jersey has generally increased compared to the prior century. Compared to today, climate projections indicate that New Jersey is expected to experience increased precipitation mainly in the winter and spring seasons through 2100 with both moderate and high greenhouse gas emissions. Higher emission projections generate higher winter and spring mean precipitation compared to moderate emissions. Increased precipitation can cause more flooding and lead to flooding impacts such as power outages, displacement of residents, and/or crop loss.`,
        topText:
            "Mean seasonal precipitation is the average amount of rainfall experienced in the winter, spring, summer, and fall seasons throughout the state. Average rainfall during the winter and spring seasons are expected to increase slightly by 2100. Summer and fall are not expected to experience much change in rainfall by 2100.",
        belowViz:
            "Rainfall in every season is highly variable from year to year in New Jersey. The range of projections is very wide, highlighting how variable and uncertain projections in mean seasonal rainfall are. However, what is more certain is that extreme rainfall is projected to become more intense throughout the rest of the century. An example of an extreme rainfall event would be where the average amount of 3 months worth of rain falls in a 3 hour period.",
        //  For more information on extreme rainfall, please see our Extreme Rainfall Tool (Link).   ',
        mapTitle: "Average Precipitation (Inches)",
        statsTitle: "Seasonal Precipitation",
        statsUnit: "inches",

        chartTitle: "Precipitation",
        chartYAxisText: "Total seasonal precipitation, inches",
        chartYAxisMax: 30,
        about: {
            desc: `With this tool, the simulated mean of seasonal precipitation can be viewed for the whole of New Jersey over historical and future time periods. Note the historical period of 1991-2020 is climate model data and not based solely on observed precipitation measurements. The future time periods are simulated using greenhouse gas emissions scenarios RCP 4.5 (moderate emissions) and RCP 8.5 (high emissions). The modeled data have been extracted from the U.S. National Oceanic and Atmospheric Administration’s Applied Climate Information System and localized to New Jersey. `,
            variables: [
                {
                    title: "Mean Precipitation (inches)",
                    desc: [
                        "Mean daily precipitation is simulated using a grid over the state. The mean daily precipitation is totaled for the selected season at each grid cell. The statewide seasonal mean is calculated by averaging these grid cell values over the whole state. The 10th through 90th percentile range of simulated seasonal mean precipitation is represented by the light blue shading.",
                    ],
                },
            ],
        },
        changeLegend: {
            title: "Change in inches",
            range: [
                "#a6611a",
                "#c3924b",
                "#dfc27d",
                "#eadcb9",
                "#f5f5f5",
                "#bbe1db",
                "#80cdc1",
                "#40a999",
                "#018571",
            ],
            domain: ["-4", "-3", "-2", "-1", " 0", "+1", "+2", "+3", "+4"],
        },
        meanLegend: {
            title: "Precipitation in inches",
            range: [
                "#e8e8e8",
                "#e2eef9",
                "#cde0f2",
                "#b0d2e8",
                "#89bfdd",
                "#60a6d2",
                "#3e8ec4",
                "#2172b6",
                "#0a549e",
                "#08306b",
            ],
            domain: ["9", "10", "11", "12", "13", "14", "15", "16", "17", "18"],
        },
    },
    activeRcp: {
        scenario: "Moderate Emissions",
        description: "emissions peak around 2040 then decline",
        mapVar: "RCP45",
        chartVar: "rcp45",
    },
    activeTopic: "Mean Seasonal Precipitation",
    activeViz: "Chart",
    activeYear: { year: "2021-2040", chartVar: 2040 },
    change: true,
    activeSeason: { name: "Winter", def: "(Dec, Jan, Feb)", mapName: "Winter", chartVar: 2 },
    activeColors: {
        background: theme.RUBlue,
        text: "white",
    }
}

const reducer = (state, action) => {
    let { type, payload } = action
    switch (type) {
        case 'SET_ACTIVE_TOPIC': {
            let freshParam = pagesArray.find(item => item.tileTitle === payload)
            let hotTopic = heatParams.includes(freshParam.param)
            let freshColors = hotTopic ? theme.RUOrange : theme.RUBlue
            let freshSeason = hotTopic ? { name: "Summer", def: "(Jun, Jul, Aug)", mapName: "Summer", chartVar: 8 } : { name: "Winter", def: "(Dec, Jan, Feb)", mapName: "Winter", chartVar: 2 }
            return { ...state, activeTopic: payload, activeParam: freshParam, activeColors: { ...state.activeColors, background: freshColors }, activeSeason: freshSeason }
        }
        case 'SET_ACTIVE_PARAM': {
            console.log(payload)
            let freshParam = pagesArray.find(item => item.param === payload)
            let freshColors = heatParams.includes(freshParam?.param) ? theme.RUOrange : theme.RUBlue

            let freshTopic = freshParam.tileTitle
            return { ...state, activeTopic: freshTopic, activeParam: freshParam, activeColors: { ...state.activeColors, background: freshColors } }
        }
        case 'SET_ACTIVE_VIZ':
            {
                console.log('viz payload', payload)
                return { ...state, activeViz: payload }
            }
        case 'SET_ACTIVE_YEAR':
            {
                console.log('year payload', payload)
                return { ...state, activeYear: payload }
            }
        case 'SET_ACTIVE_RCP':
            {
                console.log('RCP payload', payload)
                return { ...state, activeRcp: payload }
            }
        case 'SET_ACTIVE_SEASON':
            {
                console.log('season payload', payload)
                return { ...state, activeSeason: payload }
            }
        case 'TOGGLE_CHANGE':
            { return { ...state, change: !state.change } }
        default: {
            return state
        }
    }
}

export { initialState, reducer }