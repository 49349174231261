// create a dictionary of map portal item ids and layer ids for all of the climate variables


const mapIds = {
    pcpnChange: {
        id: '272808b2ba0144458c899950d99b089e',

        rcp45: {
            "2040": {
                "2": { layerId: 6 },
                "5": { layerId: 5 },
                "8": { layerId: 4 },
                "11": { layerId: 3 },
            },
            "2060": {
                "2": { layerId: 11 },
                "5": { layerId: 10 },
                "8": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2080": {
                "2": { layerId: 16 },
                "5": { layerId: 15 },
                "8": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2100": {
                "2": { layerId: 21 },
                "5": { layerId: 20 },
                "8": { layerId: 19 },
                "11": { layerId: 18 },
            },
        },
        rcp85: {
            "2040": {
                "2": { layerId: 27 },
                "5": { layerId: 26 },
                "8": { layerId: 25 },
                "11": { layerId: 24 },
            },
            "2060": {
                "2": { layerId: 32 },
                "5": { layerId: 31 },
                "8": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2080": {
                "2": { layerId: 37 },
                "5": { layerId: 36 },
                "8": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2100": {
                "2": { layerId: 42 },
                "5": { layerId: 41 },
                "8": { layerId: 40 },
                "11": { layerId: 39 },
            },
        }
    },
    pcpn: {
        id: '0287558b6ff24f21af040e240eda1daf',
        rcp45: {
            "2020": {
                "8": { layerId: 6 },
                "5": { layerId: 5 },
                "2": { layerId: 4 },
                "11": { layerId: 3 },
            },


            "2040": {
                "8": { layerId: 11 },
                "5": { layerId: 10 },
                "2": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2060": {
                "8": { layerId: 16 },
                "5": { layerId: 15 },
                "2": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2080": {
                "8": { layerId: 21 },
                "5": { layerId: 20 },
                "2": { layerId: 19 },
                "11": { layerId: 18 },
            },
            "2100": {
                "8": { layerId: 26 },
                "5": { layerId: 25 },
                "2": { layerId: 24 },
                "11": { layerId: 23 },
            },
        },
        rcp85: {

            "2020": {
                "8": { layerId: 32 },
                "5": { layerId: 31 },
                "2": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2040": {
                "8": { layerId: 37 },
                "5": { layerId: 36 },
                "2": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2060": {
                "8": { layerId: 42 },
                "5": { layerId: 41 },
                "2": { layerId: 40 },
                "11": { layerId: 39 },
            },
            "2080": {
                "8": { layerId: 47 },
                "5": { layerId: 46 },
                "2": { layerId: 45 },
                "11": { layerId: 44 },
            },
            "2100": {
                "8": { layerId: 52 },
                "5": { layerId: 51 },
                "2": { layerId: 50 },
                "11": { layerId: 49 },
            },
        }
    },
    maxtChange: {
        id: '4cda54e56cff4222815519e72662d254',

        rcp45: {
            "2040": {
                "2": { layerId: 6 },
                "5": { layerId: 5 },
                "8": { layerId: 4 },
                "11": { layerId: 3 },
            },
            "2060": {
                "2": { layerId: 11 },
                "5": { layerId: 10 },
                "8": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2080": {
                "2": { layerId: 16 },
                "5": { layerId: 15 },
                "8": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2100": {
                "2": { layerId: 21 },
                "5": { layerId: 20 },
                "8": { layerId: 19 },
                "11": { layerId: 18 },
            },
        },
        rcp85: {
            "2040": {
                "2": { layerId: 27 },
                "5": { layerId: 26 },
                "8": { layerId: 25 },
                "11": { layerId: 24 },
            },
            "2060": {
                "2": { layerId: 32 },
                "5": { layerId: 31 },
                "8": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2080": {
                "2": { layerId: 37 },
                "5": { layerId: 36 },
                "8": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2100": {
                "2": { layerId: 42 },
                "5": { layerId: 41 },
                "8": { layerId: 40 },
                "11": { layerId: 39 },
            },
        }
    },
    maxt: {
        id: '583fdc9b03cd4dee90090026608d5f85',
        rcp45: {
            "2020": {
                "8": { layerId: 6 },
                "5": { layerId: 5 },
                "2": { layerId: 4 },
                "11": { layerId: 3 },
            },


            "2040": {
                "8": { layerId: 11 },
                "5": { layerId: 10 },
                "2": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2060": {
                "8": { layerId: 16 },
                "5": { layerId: 15 },
                "2": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2080": {
                "8": { layerId: 21 },
                "5": { layerId: 20 },
                "2": { layerId: 19 },
                "11": { layerId: 18 },
            },
            "2100": {
                "8": { layerId: 26 },
                "5": { layerId: 25 },
                "2": { layerId: 24 },
                "11": { layerId: 23 },
            },
        },
        rcp85: {

            "2020": {
                "8": { layerId: 32 },
                "5": { layerId: 31 },
                "2": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2040": {
                "8": { layerId: 37 },
                "5": { layerId: 36 },
                "2": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2060": {
                "8": { layerId: 42 },
                "5": { layerId: 41 },
                "2": { layerId: 40 },
                "11": { layerId: 39 },
            },
            "2080": {
                "8": { layerId: 47 },
                "5": { layerId: 46 },
                "2": { layerId: 45 },
                "11": { layerId: 44 },
            },
            "2100": {
                "8": { layerId: 52 },
                "5": { layerId: 51 },
                "2": { layerId: 50 },
                "11": { layerId: 49 },
            },
        }
    },

    mintChange: {
        id: '3d759d1b0e8b42cb9a61618ae89a4e48',

        rcp45: {
            "2040": {
                "2": { layerId: 6 },
                "5": { layerId: 5 },
                "8": { layerId: 4 },
                "11": { layerId: 3 },
            },
            "2060": {
                "2": { layerId: 11 },
                "5": { layerId: 10 },
                "8": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2080": {
                "2": { layerId: 16 },
                "5": { layerId: 15 },
                "8": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2100": {
                "2": { layerId: 21 },
                "5": { layerId: 20 },
                "8": { layerId: 19 },
                "11": { layerId: 18 },
            },
        },
        rcp85: {
            "2040": {
                "2": { layerId: 27 },
                "5": { layerId: 26 },
                "8": { layerId: 25 },
                "11": { layerId: 24 },
            },
            "2060": {
                "2": { layerId: 32 },
                "5": { layerId: 31 },
                "8": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2080": {
                "2": { layerId: 37 },
                "5": { layerId: 36 },
                "8": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2100": {
                "2": { layerId: 42 },
                "5": { layerId: 41 },
                "8": { layerId: 40 },
                "11": { layerId: 39 },
            },
        }
    },
    mint: {
        id: '1590d0cb9e5b4d00a5e33849c113ce81',
        rcp45: {
            "2020": {
                "8": { layerId: 6 },
                "5": { layerId: 5 },
                "2": { layerId: 4 },
                "11": { layerId: 3 },
            },


            "2040": {
                "8": { layerId: 11 },
                "5": { layerId: 10 },
                "2": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2060": {
                "8": { layerId: 16 },
                "5": { layerId: 15 },
                "2": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2080": {
                "8": { layerId: 21 },
                "5": { layerId: 20 },
                "2": { layerId: 19 },
                "11": { layerId: 18 },
            },
            "2100": {
                "8": { layerId: 26 },
                "5": { layerId: 25 },
                "2": { layerId: 24 },
                "11": { layerId: 23 },
            },
        },
        rcp85: {

            "2020": {
                "8": { layerId: 32 },
                "5": { layerId: 31 },
                "2": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2040": {
                "8": { layerId: 37 },
                "5": { layerId: 36 },
                "2": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2060": {
                "8": { layerId: 42 },
                "5": { layerId: 41 },
                "2": { layerId: 40 },
                "11": { layerId: 39 },
            },
            "2080": {
                "8": { layerId: 47 },
                "5": { layerId: 46 },
                "2": { layerId: 45 },
                "11": { layerId: 44 },
            },
            "2100": {
                "8": { layerId: 52 },
                "5": { layerId: 51 },
                "2": { layerId: 50 },
                "11": { layerId: 49 },
            },
        }
    },
    cddChange: {
        id: '046c015f10d94d3a8611dcf63a865fa1',

        rcp45: {
            "2040": {
                "2": { layerId: 6 },
                "5": { layerId: 5 },
                "8": { layerId: 4 },
                "11": { layerId: 3 },
            },
            "2060": {
                "2": { layerId: 11 },
                "5": { layerId: 10 },
                "8": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2080": {
                "2": { layerId: 16 },
                "5": { layerId: 15 },
                "8": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2100": {
                "2": { layerId: 21 },
                "5": { layerId: 20 },
                "8": { layerId: 19 },
                "11": { layerId: 18 },
            },
        },
        rcp85: {
            "2040": {
                "2": { layerId: 27 },
                "5": { layerId: 26 },
                "8": { layerId: 25 },
                "11": { layerId: 24 },
            },
            "2060": {
                "2": { layerId: 32 },
                "5": { layerId: 31 },
                "8": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2080": {
                "2": { layerId: 37 },
                "5": { layerId: 36 },
                "8": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2100": {
                "2": { layerId: 42 },
                "5": { layerId: 41 },
                "8": { layerId: 40 },
                "11": { layerId: 39 },
            },
        }
    },
    cdd: {
        id: 'f917f2c774de487f871278b69343e437',
        rcp45: {
            "2020": {
                "8": { layerId: 6 },
                "5": { layerId: 5 },
                "2": { layerId: 4 },
                "11": { layerId: 3 },
            },


            "2040": {
                "8": { layerId: 11 },
                "5": { layerId: 10 },
                "2": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2060": {
                "8": { layerId: 16 },
                "5": { layerId: 15 },
                "2": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2080": {
                "8": { layerId: 21 },
                "5": { layerId: 20 },
                "2": { layerId: 19 },
                "11": { layerId: 18 },
            },
            "2100": {
                "8": { layerId: 26 },
                "5": { layerId: 25 },
                "2": { layerId: 24 },
                "11": { layerId: 23 },
            },
        },
        rcp85: {

            "2020": {
                "8": { layerId: 32 },
                "5": { layerId: 31 },
                "2": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2040": {
                "8": { layerId: 37 },
                "5": { layerId: 36 },
                "2": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2060": {
                "8": { layerId: 42 },
                "5": { layerId: 41 },
                "2": { layerId: 40 },
                "11": { layerId: 39 },
            },
            "2080": {
                "8": { layerId: 47 },
                "5": { layerId: 46 },
                "2": { layerId: 45 },
                "11": { layerId: 44 },
            },
            "2100": {
                "8": { layerId: 52 },
                "5": { layerId: 51 },
                "2": { layerId: 50 },
                "11": { layerId: 49 },
            },
        }
    },
    hddChange: {
        id: '48e6a8d795e64a32ab7731e372c0e1e3',

        rcp45: {
            "2040": {
                "2": { layerId: 6 },
                "5": { layerId: 5 },
                "8": { layerId: 4 },
                "11": { layerId: 3 },
            },
            "2060": {
                "2": { layerId: 11 },
                "5": { layerId: 10 },
                "8": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2080": {
                "2": { layerId: 16 },
                "5": { layerId: 15 },
                "8": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2100": {
                "2": { layerId: 21 },
                "5": { layerId: 20 },
                "8": { layerId: 19 },
                "11": { layerId: 18 },
            },
        },
        rcp85: {
            "2040": {
                "2": { layerId: 27 },
                "5": { layerId: 26 },
                "8": { layerId: 25 },
                "11": { layerId: 24 },
            },
            "2060": {
                "2": { layerId: 32 },
                "5": { layerId: 31 },
                "8": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2080": {
                "2": { layerId: 37 },
                "5": { layerId: 36 },
                "8": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2100": {
                "2": { layerId: 42 },
                "5": { layerId: 41 },
                "8": { layerId: 40 },
                "11": { layerId: 39 },
            },
        }
    },
    hdd: {
        id: '23152c59ae6e4d23ba682267f81ff629',
        rcp45: {
            "2020": {
                "8": { layerId: 6 },
                "5": { layerId: 5 },
                "2": { layerId: 4 },
                "11": { layerId: 3 },
            },


            "2040": {
                "8": { layerId: 11 },
                "5": { layerId: 10 },
                "2": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2060": {
                "8": { layerId: 16 },
                "5": { layerId: 15 },
                "2": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2080": {
                "8": { layerId: 21 },
                "5": { layerId: 20 },
                "2": { layerId: 19 },
                "11": { layerId: 18 },
            },
            "2100": {
                "8": { layerId: 26 },
                "5": { layerId: 25 },
                "2": { layerId: 24 },
                "11": { layerId: 23 },
            },
        },
        rcp85: {

            "2020": {
                "8": { layerId: 32 },
                "5": { layerId: 31 },
                "2": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2040": {
                "8": { layerId: 37 },
                "5": { layerId: 36 },
                "2": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2060": {
                "8": { layerId: 42 },
                "5": { layerId: 41 },
                "2": { layerId: 40 },
                "11": { layerId: 39 },
            },
            "2080": {
                "8": { layerId: 47 },
                "5": { layerId: 46 },
                "2": { layerId: 45 },
                "11": { layerId: 44 },
            },
            "2100": {
                "8": { layerId: 52 },
                "5": { layerId: 51 },
                "2": { layerId: 50 },
                "11": { layerId: 49 },
            },
        }
    },
    gddChange: {
        id: '61628b5e5f594ead985d9b41bf76d410',

        rcp45: {
            "2040": {
                "2": { layerId: 6 },
                "5": { layerId: 5 },
                "8": { layerId: 4 },
                "11": { layerId: 3 },
            },
            "2060": {
                "2": { layerId: 11 },
                "5": { layerId: 10 },
                "8": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2080": {
                "2": { layerId: 16 },
                "5": { layerId: 15 },
                "8": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2100": {
                "2": { layerId: 21 },
                "5": { layerId: 20 },
                "8": { layerId: 19 },
                "11": { layerId: 18 },
            },
        },
        rcp85: {
            "2040": {
                "2": { layerId: 27 },
                "5": { layerId: 26 },
                "8": { layerId: 25 },
                "11": { layerId: 24 },
            },
            "2060": {
                "2": { layerId: 32 },
                "5": { layerId: 31 },
                "8": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2080": {
                "2": { layerId: 37 },
                "5": { layerId: 36 },
                "8": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2100": {
                "2": { layerId: 42 },
                "5": { layerId: 41 },
                "8": { layerId: 40 },
                "11": { layerId: 39 },
            },
        }
    },
    gdd: {
        id: '891967343dca414983ba866c0a33226a',
        rcp45: {
            "2020": {
                "8": { layerId: 6 },
                "5": { layerId: 5 },
                "2": { layerId: 4 },
                "11": { layerId: 3 },
            },


            "2040": {
                "8": { layerId: 11 },
                "5": { layerId: 10 },
                "2": { layerId: 9 },
                "11": { layerId: 8 },
            },
            "2060": {
                "8": { layerId: 16 },
                "5": { layerId: 15 },
                "2": { layerId: 14 },
                "11": { layerId: 13 },
            },
            "2080": {
                "8": { layerId: 21 },
                "5": { layerId: 20 },
                "2": { layerId: 19 },
                "11": { layerId: 18 },
            },
            "2100": {
                "8": { layerId: 26 },
                "5": { layerId: 25 },
                "2": { layerId: 24 },
                "11": { layerId: 23 },
            },
        },
        rcp85: {

            "2020": {
                "8": { layerId: 32 },
                "5": { layerId: 31 },
                "2": { layerId: 30 },
                "11": { layerId: 29 },
            },
            "2040": {
                "8": { layerId: 37 },
                "5": { layerId: 36 },
                "2": { layerId: 35 },
                "11": { layerId: 34 },
            },
            "2060": {
                "8": { layerId: 42 },
                "5": { layerId: 41 },
                "2": { layerId: 40 },
                "11": { layerId: 39 },
            },
            "2080": {
                "8": { layerId: 47 },
                "5": { layerId: 46 },
                "2": { layerId: 45 },
                "11": { layerId: 44 },
            },
            "2100": {
                "8": { layerId: 52 },
                "5": { layerId: 51 },
                "2": { layerId: 50 },
                "11": { layerId: 49 },
            },
        }
    },

}

export default mapIds