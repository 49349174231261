import { useEffect, useState } from "react"
import rainData from './rainData.json'

const useRainData = (muni) => {
    // console.log('using rain data')
    const [filteredRainData, setFilteredRainData] = useState()

    useEffect(() => {
        let data = rainData.data.find(item => item.town_name.toLowerCase() === muni?.toLowerCase())
        setFilteredRainData(data)
    }, [muni])

    return filteredRainData
}

export default useRainData