import React, { useReducer } from "react";
import { Route, useSearchParams, Routes } from "react-router-dom";
import SiteHeader from "./components/SiteHeader/SiteHeader";
import Page from "./components/PageTemplate/Page";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { theme } from "./components/theme";
import AppContext from "./AppContext";
import { initialState, reducer } from "./reducer";
import ExtremeRain from "./pages/ExtremeRain/ExtremeRain";
import HeatWaves from "./pages/HeatWaves/HeatWaves";
import Pcpn from "./pages/Pcpn";
import Maxt from "./pages/Maxt";
import Mint from "./pages/Mint";
import Cdd from "./pages/Cdd";
import Gdd from "./pages/Gdd";
import Hdd from "./pages/Hdd";
// import ReactGA from "react-ga4";

function App() {
  // const trackingId = process.env.REACT_APP_GA_TRACKING;
  // console.log(trackingId)
  // ReactGA.initialize(trackingId);
  // ReactGA.send("pageview")

  const [state, dispatch] = useReducer(reducer, initialState)

  const [searchParams] = useSearchParams();
  let modalStatus = searchParams.get("modalStatus");
  // console.log('test', process.env.REACT_APP_BASENAME)
  return (
    <AppContext.Provider value={[state, dispatch]}>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <SiteHeader modalStatus={modalStatus} />
        <Routes>
          <Route path="/" element={<Page />} />
          <Route path="/cdd" element={<Cdd />} />
          <Route path="/extremeRain" element={<ExtremeRain />} />
          <Route path="/gdd" element={<Gdd />} />
          <Route path="/hdd" element={<Hdd />} />
          <Route path="/heatWaves" element={<HeatWaves />} />
          <Route path="/maxt" element={<Maxt />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/pcpn" element={<Pcpn />} />
          {/* <Route path="/:topic" element={<Page />} /> */}
        </Routes>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;

const CSSReset = createGlobalStyle`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; /* 1rem = 10px */
  box-sizing: border-box;
}

body {
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;
