import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import HC_exporting from 'highcharts/modules/exporting'
import { theme } from "../../components/theme"

HC_exporting(Highcharts)
highchartsMore(Highcharts);

const ChanceChart = ({ data }) => {

    const widthRef = useRef(null)
    // console.log('ChanceChart', data)
    //** add custom colors to data series - 6 colors for 6 series */
    // let { RUBlue, RUTeal, RUGreen, RUYellow, RUOrange, RURed } = theme
    // let colors = [RURed, RUBlue, RUOrange, RUGreen, RUYellow, RUTeal]
    // let coloredData = data?.map((item, i) => ({ ...item, color: colors[i] }))

    const [width, setWidth] = useState(1300)

    useEffect(() => {
        if (widthRef.current){
            setWidth(widthRef?.current?.clientWidth * .9)
        }
    }, [])
    

    const chartRef = useRef(null);

    const chartOptions = {
        chart: {
            width: width,
            type: 'column',
        },
        title: {
            text: 'Difference in probability of extreme rainfall events',

        },
        subtitle: {
            text: 'Chance that given inches will fall in a 24 hour event across various climate scenarios'
        },
        xAxis: {
            categories: [
                'Historical',
                'Moderate Emissions, mid-century',
                'Moderate Emissions, late-century',
                'High Emissions, mid-century',
                'High Emissions, late-century',
            ],
            crosshair: true
        },
        yAxis: { // Secondary yAxis
            gridLineWidth: 0,
            title: {
                text: '% chance',
            },
            labels: {
                format: '{value}',
            },
            // opposite: true,
            // max: 50,
        },
        tooltip: {
            pointFormat: '<tr><td style="color:{series.color};padding:0">Chance: </td>' +
                '<td style="padding:0"><b>{point.y:.2f} %</b></td></tr>',
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,

                borderWidth: 0
            }
        },
        series: [{
            name: 'Scenario',
            color: theme.RUTeal,
            data: data
        }]
    }

    return (
        <div ref={widthRef}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
            />
        </div>
    );
}
export default ChanceChart