import styled from "styled-components"

const GeneralStyles = {
    PageCont: styled.div`
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          max-width: 1300px;
          align-items: flex-start;
          justify-content: space-evenly;
          margin-bottom: 3rem;
          `,
    SectionCont: styled.section`
     margin: 3rem 1rem; 
     display: flex; 
     flex-direction: column; 
     align-items: center; 
     justify-content: center;
    `,

    // FlexCol: styled(Section)`
    //       flex-direction: column;
    //       `,
    // ChooseViz: styled(SelectionBlock)`
    //       margin-top: 3rem;
    //       `,


    // ChartViz: styled(Section)`
    //       flex-direction: column;
    //       width: 66vw;
    //       margin: 3rem;
    //       `,
    // ChartWrapper: styled.div`
    //       flex-direction: column;
    //       align-items: center;
    //       justify-content: center;
    //       width: 100%;
    //       `,
    // VizStats: styled(Section)`
    //       margin-bottom: 2rem;
    //       `,


    // LowerCont: styled.div`
    //       width: 100%;
    //       display: flex;
    //       align-items: flex-start;
    //       justify-content: flex-start;

    //       @media screen and (max-width: ${({ theme }) => theme.mobile}) {
    //         display: flex;
    //       justify-content: center;
    //       flex-wrap: wrap;
    //       flex-direction: row-reverse; /* <--- Really freaking cool! */
    //   // align-items: center;
    // }
    //       `,
}

export default GeneralStyles