import React from "react";
import styled from 'styled-components'

const Stats = ({ title, values, color }) => {
  let min = values.min.toFixed(1);
  let avg = values.average.toFixed(1);
  let max = values.max.toFixed(1);

  // console.log(color.background)

  return (
    <Styles.Wrapper color={color.background} id='stats'>
      <h3>{title}</h3>
      <div className="stats-content">
        <div className="stats-val">
          <span>Minimum</span>
          <span>{min}</span>
        </div>
        <div className="stats-val">
          <span>Average</span>
          <span>{avg}</span>
        </div>
        <div className="stats-val">
          <span>Maximum</span>
          <span>{max}</span>
        </div>
      </div>
    </Styles.Wrapper>
  );
};

export default React.memo(Stats);

const Styles = {
  Wrapper: styled.div`
  background-color: ${({ color }) => color + 50};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #616161;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    .stats h3 {
      font-size: 1.5rem;
      margin: 0;
    }
  
    .stats-content{
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-evenly;
      
    }
  
    .stats-val {
      display: flex;
      flex-direction: column;
    }
  `,
  VizStats: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  `,

}
