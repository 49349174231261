import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

import { setAssetPath } from '@esri/calcite-components/dist/components'
setAssetPath("https://js.arcgis.com/calcite-components/1.4.2/assets");

const basename = process.env.REACT_APP_BASENAME;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={basename}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
