import React from 'react'
import { aboutRcps } from './aboutRCPs'
const AboutRcps = () => {
  return (
    <div>
        {aboutRcps.map(item => (
            <>
            <h2>
                {item.title}
            </h2>
            <p>{item.desc}</p>
            </>
        ))}
        
    </div>
  )
}

export default AboutRcps