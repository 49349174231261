export const theme = {
  RUGrey: '#5f6a72',
  RUBlue: '#007fac',
  RUTeal: '#00626d',
  RUGreen: '#9ea900',
  RUYellow: '#ebb600',
  RUOrange: '#e76f00',
  RURed: '#cc0033',
  RUBeige: '#dfd2b3',
  RUBrown: '#703221',
  RUGreyBeige: '#c1bbab',
  boxShadow: '0 3px 15px rgba(0,0,0,0.2)',
  mobile: '750px',
}
