import styled from "styled-components";

const Style = {
    Table: styled.table`
        font-family: sans-serif;
        font-size: 1.6rem;
        border-collapse: collapse;
        margin: 2rem;
        maxWidth: 900px;


        td,th {
            border: 1px solid #ddd;
            padding: 0.8rem;
            text-align: center;
        }
        tr: nth-child(even) {
            background-color: lightgray;
        }

        tr: nth-child(odd) {
            background-color: rgb(240, 240, 240);
        }

        tr:hover {
            filter: brightness(95 %);
        }

        th {
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            // background-color: #04AA6D;
            // color: white;
            border-top: 3px solid gray;
        }
    `,
}

const RainTable = ({ data, caption }) => {

    return (
        <Style.Table>
            <caption>{caption}</caption>
            <thead>
                <tr>
                    {!!data[0].name && <th>Storm Type</th>}
                    <th>Historical</th>
                    <th>Moderate Emissions, Mid-century</th>
                    <th>Moderate Emissions, Late-century</th>
                    <th>High Emissions, Mid-century</th>
                    <th>High Emissions, Late-century</th>
                </tr>
            </thead>
            <tbody>
                {!!data[0].name
                    ? data.map((item, i) => (
                        <tr key={i}>
                            {/* {console.log('rain chart', item.name.split('('))} */}
                            <td><div style={{ display: 'flex', flexDirection: 'column' }}><span>{item.name.split('(')[0]}</span><span>{'(' + item.name.split('(')[1]}</span></div></td>
                            {item.data.map((point, i) => (<td key={i}>{point.toFixed(2)}</td>))}
                        </tr>
                    ))
                    : <tr>
                        {data.map((item, i) => <td key={i}>{item.toFixed(2)}</td>)}
                    </tr>
                }
            </tbody>
        </Style.Table>
    )
}
export default RainTable