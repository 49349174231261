import { useState } from "react"
import GeneralStyles from "../../GeneralStyles"
import ToolHeader from "../../components/ToolHeader"
import TopicBar from "../../components/TopicBar"
import useRainData from "./useRainData"
import LocationSection from "./LocationSection"
import prepareStdSeries from "./prepareStdSeries";
import RainTable from "./RainTable"
import { useLocation } from "react-router-dom"
import CustomSection from "./CustomSection"
import useCheckPath from "../../tools/useCheckPath"
import prepareCustSeries from "./prepareCustSeries"
import ChanceChart from "./ChanceChart"
// import RainChart from "./RainChart"
// import InchesChart from "./InchesChart"

const ExtremeRain = () => {

    //** sync topic state with path */
    let { pathname } = useLocation()
    useCheckPath(pathname)

    const [county, setCounty] = useState('')
    const [muni, setMuni] = useState('')
    const [stormType, setStormType] = useState('')

    let filteredRainData = useRainData(muni)

    let preppedData = (!!filteredRainData && prepareStdSeries(filteredRainData)) ?? []

    let chartData = {}

    if (filteredRainData && stormType.length > 0) {
        let storm = stormType.split('year')[1] + '.0'
        let todaysRainfall = filteredRainData.Atlas14[`24hr_mean`][storm]
        // console.log('selected storm return period', storm)
        // console.log('atlas 14 amount of rainfall', todaysRainfall)


        //     console.log('power = (amount-b)/a where b and a are the regression parameters for the given location, scenario, and duration')  
        //     console.log('probability = 1/Math.exp(power)*100 where Math.exp(power) = return period')
        //     console.log('result is percent chance')

        chartData = prepareCustSeries(todaysRainfall, filteredRainData, 'inches')

    }

    // console.log("extreme rain data", chartData)

    return (
        <GeneralStyles.PageCont>
            <TopicBar />
            <ToolHeader />
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '1300px' }}>
                <LocationSection county={county} muni={muni} setCounty={setCounty} setMuni={setMuni}
                />
                {!!preppedData && <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-3rem', marginBottom: '2rem' }}>
                        <label style={{
                            display: 'block',
                            fontSize: '1.6rem',
                            margin: '0.5rem',
                            textTransform: 'capitalize',
                            letterSpacing: '1px',
                            textAlign: 'left',
                        }}>
                            Select storm type
                        </label>

                        <select
                            style={{ padding: '1rem' }}
                            name='stormType'
                            id='stormType'
                            value={stormType}
                            onChange={({ target }) => setStormType(target.value)}
                        >
                            <option value={''}>Please select a storm type</option>
                            {preppedData?.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    {chartData && <ChanceChart data={chartData} />}
                    <p>NOTE: the historical data provided in this chart and table are sourced from the NOAA Atlas 14 dataset <a href='https://hdsc.nws.noaa.gov/pfds/' target='_blank' rel='noreferrer'>(https://hdsc.nws.noaa.gov/pfds/)</a>. Historical data in the “Build your own storm” tool represent mathematical approximations based on the data to enable the user to test multiple storm probabilities and rainfall amounts, resulting in an offset between these two. Assume that the “Build your own storm” tool is best used for exploring the data space while the chart and table above provide more stringent benchmarks following the NJDEP methodology for projecting future extreme rainfall <a href='https://dspace.njstatelib.org/handle/10929/93913' target='_blank' rel='noreferrer'>(https://dspace.njstatelib.org/handle/10929/93913)</a>. </p>

                    <RainTable data={preppedData} caption='Rainfall in inches' />
                    <CustomSection data={filteredRainData} />
                </>}
            </div>
        </GeneralStyles.PageCont >
    )
}
export default ExtremeRain